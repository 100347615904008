import { Select } from "antd";
import { useField } from "formik";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Typography } from "../../stories/Typography";
import SVGBucketStatus from "./goal-icons/bucket";
import SVGCactusStatus from "./goal-icons/cactus";
import SVGCakeStatus from "./goal-icons/cake";
import SVGMeditateStatus from "./goal-icons/meditate";
import SVGMontainStatus from "./goal-icons/montain";
import { GoalDifficultyContainer, GoalDifficultyOptionContainer, GoalDifficultySelect, GoalDifficultyTypoContainer } from "./goalDIfficulty.style";





const { Option } = Select;

//Formik props field are passed to allow the goal status usage in forms
const GoalDifficulty = ({motivationLevel, ...props}) => {
  console.log('motivationLevel ',motivationLevel)
  const [showDifficultyChoice, setShowDifficultyChoice] = useState(false);
  const [difficulty, setDifficulty] = useState(motivationLevel);
  const componentRef = useRef(null);

  //used to pass and update Formik form values
  // eslint-disable-next-line no-unused-vars
  const [field,meta,helpers] = useField(props);
  const { setValue } = helpers;

  const currentDifficulty = useMemo(() => {
    console.log('difficulty ',difficulty)
    switch(difficulty) {
      case 2:
        return {svg : <SVGCakeStatus/>, label: 'Cake', motivationLevel: 2};
      case 4 :
        return {svg : <SVGBucketStatus/> , label : 'Bucket',motivationLevel: 4};
      case 6:
        return {svg : <SVGMeditateStatus/> , label : 'Meditate',motivationLevel: 6};
      case 8:
        return {svg : <SVGCactusStatus/> , label : 'Cactus',motivationLevel: 8};
      case 10:
        return {svg : <SVGMontainStatus/> , label : 'Montain',motivationLevel: 10};
      default:
        return {svg : <SVGMeditateStatus/> , label : 'Meditate',motivationLevel: 6};
    }
  }, [difficulty]);
  const difficultyChoices = useMemo(() => {
    const choices = [
      { label: 'Cake', icon: <SVGCakeStatus />,  motivationLevel: 2},
      { label: 'Bucket', icon: <SVGBucketStatus /> , motivationLevel: 4},
      { label: 'Meditate', icon: <SVGMeditateStatus /> , motivationLevel: 6},
      { label: 'Cactus', icon: <SVGCactusStatus /> , motivationLevel: 8},
      { label: 'Montain', icon: <SVGMontainStatus /> , motivationLevel: 10},
    ].filter((elt) => elt.label !== currentDifficulty.label);
    return choices;
  }, [currentDifficulty.label]);

  const handleChangeDifficulty = useCallback((difficultyValue) => {
    setDifficulty(difficultyValue);
    setValue(difficultyValue);
  }, [setValue]);
  const handleDifficultyCardClick = useCallback(() => {
    setShowDifficultyChoice(!showDifficultyChoice);
  }, [showDifficultyChoice]);
  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setShowDifficultyChoice(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return <>
  <GoalDifficultyContainer  ref={componentRef} onClick={handleDifficultyCardClick}>
    {currentDifficulty.svg}
    <GoalDifficultyTypoContainer>
    <Typography color={currentDifficulty.label ? "black" : "#8A8390"} fz="16px" fw="500" lh="110%" ls="-2%">
      {currentDifficulty.label ?? 'DEFINE'}
    </Typography>
    <Typography color="#8A8390" fz="12px" fw="500" lh="90%" ls="-2%">
    LEVEL
    </Typography>
  </GoalDifficultyTypoContainer>
    <GoalDifficultySelect
    open={showDifficultyChoice}
    size="large"
    className="difficulty"
    dropdownClassName="dropdown-difficulty"
    name="difficulty"
    value={currentDifficulty.label}
    onBlur={field.onBlur}
    onChange={(difficultyValue) => handleChangeDifficulty(difficultyValue)}
    >
      {
      difficultyChoices.map((elt, index) => {
        return <Option key={index} value={elt.motivationLevel} className="fade-in">
          <GoalDifficultyOptionContainer>
          {elt.icon}
            <Typography>
              {elt.label}
          </Typography>
        </GoalDifficultyOptionContainer></Option>
      })
    }
    </GoalDifficultySelect>
  </GoalDifficultyContainer>

  </>;
}

export default GoalDifficulty;
