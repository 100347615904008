export const getLocalStorageObject = (id: string, key: string) => {
  const ids = localStorage.getItem(key);

  return ids && JSON.parse(ids).includes(id);
};

export const setLocalStorageObject = (id: string, key: string) => {
  const ids = localStorage.getItem(key);

  if (ids) {
    localStorage.setItem(key, JSON.stringify([...JSON.parse(ids), id]));
  } else {
    localStorage.setItem(key, JSON.stringify([id]));
  }
};
