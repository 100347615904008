const SVGCactusStatus = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.71199 15.4029C5.76762 15.4029 3.89847 15.2929 2.76378 14.4984C2.01748 13.9759 1.57408 13.3957 1.44592 12.7739C1.32951 12.2093 1.4696 11.6387 1.86231 11.0779C2.25503 10.517 2.74334 10.1902 3.31369 10.1065C3.94134 10.0143 4.63862 10.2325 5.38491 10.755C5.98921 11.1782 6.55467 11.9164 7.06553 12.9491C7.07509 12.9684 7.08369 12.9882 7.09117 13.0085L7.66597 14.5565C7.73564 14.7441 7.71096 14.9538 7.59962 15.1201C7.48829 15.2864 7.30391 15.3891 7.10388 15.3962C7.00517 15.3997 6.87177 15.4029 6.71199 15.4029Z"
      fill="#78BE14"
    />
    <path
      d="M18.0088 7.12961C17.7426 7.12961 17.4993 6.95833 17.4156 6.69598L17.0178 5.45057C16.9817 5.33745 16.9786 5.21639 17.009 5.10164C17.3395 3.85212 17.7952 2.93051 18.3633 2.36244C19.0075 1.71822 19.6559 1.38229 20.2904 1.36405C20.8671 1.34793 21.4043 1.58453 21.8884 2.06868C22.3725 2.55279 22.6096 3.09046 22.593 3.66663C22.5748 4.30117 22.2389 4.94959 21.5947 5.59381C20.7421 6.44634 19.2415 6.89277 18.1329 7.11719C18.0913 7.12554 18.0497 7.12961 18.0088 7.12961Z"
      fill="#459600"
    />
    <path
      d="M15.4275 17.9809H8.57104C8.37873 17.9809 8.19718 17.8921 8.07912 17.7403C6.32384 15.4831 5.14453 11.8234 5.14453 8.63348C5.14453 6.18816 5.82473 4.30925 7.16619 3.04893C8.33997 1.94619 10.0112 1.36328 11.9993 1.36328C13.9874 1.36328 15.6586 1.94619 16.8324 3.04893C18.1739 4.30925 18.8541 6.18816 18.8541 8.63348C18.8541 11.8234 17.6747 15.4831 15.9194 17.7403C15.8014 17.8921 15.6198 17.9809 15.4275 17.9809Z"
      fill="#B4E61E"
    />
    <path
      d="M15.4282 17.9809C15.6205 17.9809 15.8021 17.8921 15.9201 17.7403C17.6754 15.4831 18.8548 11.8234 18.8548 8.63348C18.8548 6.18816 18.1746 4.30925 16.8331 3.04893C15.6593 1.94619 13.988 1.36328 12 1.36328V17.9809H15.4282Z"
      fill="#78BE14"
    />
    <path
      d="M13.8297 17.9809H10.1729C9.92101 17.9809 9.69385 17.8292 9.59734 17.5964C8.67465 15.3716 8.05469 11.7697 8.05469 8.63348C8.05469 6.25787 8.40951 4.42823 9.10936 3.19546C9.96899 1.68113 11.1565 1.36328 12.0014 1.36328C12.8462 1.36328 14.0337 1.68113 14.8934 3.19546C15.5932 4.42823 15.948 6.25787 15.948 8.63348C15.948 11.7697 15.3281 15.3717 14.4054 17.5965C14.3088 17.8292 14.0817 17.9809 13.8297 17.9809Z"
      fill="#78BE14"
    />
    <path
      d="M13.8284 17.9809C14.0803 17.9809 14.3074 17.8292 14.4039 17.5965C15.3267 15.3717 15.9467 11.7697 15.9467 8.63348C15.9467 6.25787 15.5919 4.42823 14.892 3.19546C14.0324 1.68113 12.8448 1.36328 12 1.36328V17.9809H13.8284Z"
      fill="#459600"
    />
    <path
      d="M15.248 22.6336H8.75568C7.44567 22.6336 6.32768 21.6976 6.09736 20.408L5.5722 17.4671C5.5398 17.2855 5.58944 17.0989 5.70789 16.9575C5.82624 16.8161 6.00123 16.7344 6.18568 16.7344H17.818C18.0024 16.7344 18.1774 16.8161 18.2958 16.9575C18.4142 17.0989 18.4639 17.2855 18.4315 17.4671L17.9063 20.4079C17.676 21.6976 16.558 22.6336 15.248 22.6336Z"
      fill="#B96723"
    />
    <path
      d="M17.8162 16.7344H12V22.6336H15.2462C16.5562 22.6336 17.6742 21.6976 17.9045 20.4079L18.4296 17.4671C18.462 17.2855 18.4124 17.0989 18.294 16.9575C18.1756 16.8161 18.0006 16.7344 17.8162 16.7344Z"
      fill="#9B501E"
    />
  </svg>
);
export default SVGCactusStatus;
