import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ButtonWrapper from './styles';

class Button extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object
    ]).isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    refFor: PropTypes.object,
    href: PropTypes.string,
    type: PropTypes.string,
    styles: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    textColor: PropTypes.string,
    icon: PropTypes.object
  };

  static defaultProps = {
    styles: 'primary',
    size: 'small',
    type: 'button'
  };

  getProps = () => {
    const {
      styles,
      size,
      textColor,
      onClick,
      refFor,
      icon,
      style
    } = this.props;
    const buttonProps = {};

    buttonProps.styles = styles;
    buttonProps.size = size;
    buttonProps.icon = icon;
    buttonProps.textColor = textColor;
    buttonProps.onClick = onClick;
    buttonProps.refFor = refFor;
    buttonProps.style = style;

    return buttonProps;
  };

  renderContent = () => {
    const { href, type, disabled, children, icon, buttonStyle } = this.props;

    if (href) {
      return (
        <Link
          to={href}
          {...(disabled && { disabled })}
          {...(buttonStyle && { style: buttonStyle })}
        >
          {icon ? (
            <>
              {icon}
              <span>{children}</span>
            </>
          ) : (
            <>{children}</>
          )}
        </Link>
      );
    }
    return (
      <button
        {...(type && { type })}
        {...(disabled && { disabled })}
        {...(buttonStyle && { style: buttonStyle })}
      >
        {icon ? (
          <>
            {icon}
            <span>{children}</span>
          </>
        ) : (
          <>{children}</>
        )}
      </button>
    );
  };

  render() {
    const { className } = this.props;

    return (
      <ButtonWrapper className={className} {...this.getProps()}>
        {this.renderContent()}
      </ButtonWrapper>
    );
  }
}

export default Button;
