import { useField } from "formik";
import moment from "moment";
import { useCallback } from "react";
import { StyledDatePicker } from "./formikDatePicker.style";






const FormikDatePicker = ({name, placeholder,disabledDate, ...props}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue, setTouched } = helpers;

  const handleChange = useCallback((value) => {
    const formattedValue = moment(value).format('YYYY-MM-DD');
    setValue(formattedValue);
  }, [setValue]);
  return <>
    <StyledDatePicker
    {...field}
    {...props}
    value={field.value ? moment(field.value) : null}
    format="DD/MM/YYYY"
    placeholder={placeholder}
    disabledDate={disabledDate}
    showToday
    onChange={handleChange}
    onBlur={() => setTouched(true)}
    />
      {meta.touched && meta.error ? (
        <div style={{ color: 'red' }}>{meta.error}</div>
      ) : null}
  </>;
}




export default FormikDatePicker;
