import Cookies from 'js-cookie';

export const setCookies = (key: string, value: string, expires: number = 30) => Cookies.set(key, value, { expires });

export const getCookies = (key: string) => Cookies.get(key);

export const removeCookies = (keys: string[]) => {
  keys.forEach(key => {
    Cookies.remove(key);
  });
};
