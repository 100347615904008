// see: https://www.daggala.com/reusable-media-queries-with-styled-components/

const breakpoints = {
  xs: 0,
  sm: 578,
  md: 834,
  lg: 1200,
  xlg: 1800,
};

const screenSizes = {
  xs: breakpoints.xs,
  sm: breakpoints.sm,
  md: breakpoints.md,
  mdIpad: breakpoints.md + 1,
  lg: breakpoints.lg,
  xlg: breakpoints.xlg,
};

export const maxWidthQuery = (key: keyof typeof screenSizes) => {
  return (style: TemplateStringsArray | String) => `@media (max-width: ${screenSizes[key]}px) { ${style} }`;
};

export const minWidthQuery = (key: keyof typeof screenSizes) => {
  return (style: TemplateStringsArray | String) => `@media (min-width: ${screenSizes[key]}px) { ${style} }`;
};
