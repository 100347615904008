import styled, { css } from 'styled-components';
import { layoutSizes } from '../../constants';
import config from '../Grid/config';

type Props = {
  start: 'xs' | 'sm' | 'md' | 'lg';
  center: 'xs' | 'sm' | 'md' | 'lg';
  end: 'xs' | 'sm' | 'md' | 'lg';
  top: 'xs' | 'sm' | 'md' | 'lg';
  middle: 'xs' | 'sm' | 'md' | 'lg';
  bottom: 'xs' | 'sm' | 'md' | 'lg';
  around: 'xs' | 'sm' | 'md' | 'lg';
  between: 'xs' | 'sm' | 'md' | 'lg';
  first: 'xs' | 'sm' | 'md' | 'lg';
  last: 'xs' | 'sm' | 'md' | 'lg';
  clearMargin: boolean;
  fluid: boolean;
  reverse: boolean;
};

export const Row = styled.div<Partial<Props>>`
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  flex-direction: row;
  ${p =>
    css`
      ${layoutSizes.map(
        t =>
          config(p).container[t] &&
          config(p).media[t]`
      `,
      )}
    `}

  ${p =>
    !p.fluid &&
    css`
      ${layoutSizes.map(
        t =>
          config(p).gutterWidth[t] &&
          config(p).media[t]`
      `,
      )}
    `}

  ${p =>
    !p.fluid &&
    p.clearMargin &&
    css`
      ${layoutSizes.map(
        t =>
          config(p).gutterWidth[t] &&
          config(p).media[t]`
          margin-right: ${p => config(p).outerMargin[t] * -1}px;
          margin-left: ${p => config(p).outerMargin[t] * -1}px;
      `,
      )}
    `}

  ${p =>
    p.reverse &&
    `
    flex-direction: row-reverse;
  `}

  ${p =>
    p.start &&
    config(p).media[p.start]`
    justify-content: flex-start;
  `}

  ${p =>
    p.center &&
    config(p).media[p.center]`
    justify-content: center;
  `}

  ${p =>
    p.end &&
    config(p).media[p.end]`
    justify-content: flex-end;
  `}

  ${p =>
    p.top &&
    config(p).media[p.top]`
    align-items: flex-start;
  `}

  ${p =>
    p.middle &&
    config(p).media[p.middle]`
    align-items: center;
  `}

  ${p =>
    p.bottom &&
    config(p).media[p.bottom]`
    align-items: flex-end;
  `}

  ${p =>
    p.around &&
    config(p).media[p.around]`
    justify-content: space-around;
  `}

  ${p =>
    p.between &&
    config(p).media[p.between]`
    justify-content: space-between;
  `}

  ${p =>
    p.first &&
    config(p).media[p.first]`
    order: -1;
  `}

  ${p =>
    p.last &&
    config(p).media[p.last]`
    order: 1;
  `}
`;
