import styled from 'styled-components';
import Lumis from '../Lumis/styles';
import media, { mediaMax } from '../../styles/mixins/media';
// eslint-disable-next-line import/no-unresolved
import placeholder from '../../images/placeholder.svg';

export const UserProfileWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: ${(props) => (props.small ? props.theme.utils.rem(45) : props.theme.utils.rem(90))};
  height: ${(props) => (props.small ? props.theme.utils.rem(45) : props.theme.utils.rem(90))};

  ${(props) =>
    props.onClick &&
    `
    cursor: pointer;
  `}
  margin-top: 0px;
  margin-right: 19px;

  ${media.sm`
    width: ${(props) => (props.small ? props.theme.utils.rem(45) : props.theme.utils.rem(112))};
    height: ${(props) => (props.small ? props.theme.utils.rem(45) : props.theme.utils.rem(112))};
    margin-top: -3px;
    margin-right: 5px;
  `}

  ${Lumis} {
    ${(props) => props.theme.utils.horizontalyCentered()}
    bottom: ${(props) => props.theme.utils.rem(-8)};
  }

  ${mediaMax.sm`
    ${(props) => props.changeOnMobile && 'width: 33px;'}
    ${(props) => props.changeOnMobile && 'height: 33px;'}
    ${(props) => props.changeOnMobile && 'margin-right: 0px;'}

  `};
`;

export const UserPicture = styled.div`
  border-radius: 100%;
  background-color: ${(props) => props.theme.colors.persimmon};
  background-image: url(${(props) => props.pictureUrl || placeholder});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  ${mediaMax.sm`
    ${(props) => props.changeOnMobile && 'top: -5px;'}
    ${(props) => props.changeOnMobile && 'position: relative;'}
  `};
`;
