import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../services/apiClient';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchProgramMentors = createAsyncThunk('userProgram/fetchProgramMentors', async userProgramId => {
  const { data } = await apiClient.get(`/mentor/program?user_program_id=${userProgramId}`);

  return data.mentors;
});

const programMentorsSlice = createSlice({
  name: 'programMentorsSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchProgramMentors.pending]: state => {
      state.status = 'loading';
    },
    [fetchProgramMentors.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchProgramMentors.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default programMentorsSlice.reducer;
