import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../../services/apiClient';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

// GET /api/user-program-answers/{userProgramId}/event/{eventId}/notes - витягує нотатки
// POST /api/user-program-answers/{userProgramId}/event/{eventId}/note - створює
// PATCH /api/user-program-answers/{userProgramId}/event/note/{noteId} - змінює
// DELTE /api/user-program-answers/{userProgramId}/event/note/{noteId} - видаляє

export const gethProgramEvent = createAsyncThunk('userProgram/gethProgramEvent', async ({ userProgramId, eventId }) => {
  const { data } = await apiClient.get(`/user-program-answers/${userProgramId}/event/${eventId}/notes`);

  return data;
});

export const addProgramEvent = createAsyncThunk('userProgram/addProgramEvent', async ({ userProgramId, eventId, content }) => {
  const body =
  {
    content: content,
  };
  const { data } = await apiClient.post(`/user-program-answers/${userProgramId}/event/${eventId}/note`, body);

  return data;
});

export const updateProgramEvent = createAsyncThunk('userProgram/updateProgramEvent', async ({ userProgramId, noteId, content }) => {
  const body =
  {
    content: content,
  };
  const { data } = await apiClient.patch(`/user-program-answers/${userProgramId}/event/note/${noteId}`, body);

  return data;
});

export const deleteProgramEvent = createAsyncThunk('userProgram/deleteProgramEvent', async ({ userProgramId, noteId }) => {

  const { data } = await apiClient.delete(`/user-program-answers/${userProgramId}/event/note/${noteId}`);

  return data;
});

const ProgramDefiniteEventSlice = createSlice({
  name: 'ProgramDefiniteEventSlice',
  initialState,
  reducers: {},
  extraReducers: {

    [gethProgramEvent.pending]: state => {
      state.status = 'loading';
    },
    [gethProgramEvent.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [gethProgramEvent.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    [addProgramEvent.pending]: state => {
      state.status = 'loading';
    },
    [addProgramEvent.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [addProgramEvent.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    [updateProgramEvent.pending]: state => {
      state.status = 'loading';
    },
    [updateProgramEvent.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [updateProgramEvent.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    [deleteProgramEvent.pending]: state => {
      state.status = 'loading';
    },
    [deleteProgramEvent.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [deleteProgramEvent.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default ProgramDefiniteEventSlice.reducer;
