import React from 'react';
import PropTypes from 'prop-types';
import Lumis from '../Lumis';
import { UserProfileWrapper, UserPicture } from './styles';

const UserProfile = props => {
  return (
    <UserProfileWrapper
      changeOnMobile={props.changeOnMobile}
      {...(props.onClick && { onClick: props.onClick })}
      small={props.size === 'small'}
    >
      <UserPicture pictureUrl={props.pictureUrl} changeOnMobile={props.changeOnMobile}></UserPicture>
      {/* TODO: Hide this for now <Lumis value={props.lumis || 0}></Lumis> */}
    </UserProfileWrapper>
  );
};

UserProfile.propTypes = {
  onClick: PropTypes.func,
  pictureUrl: PropTypes.string,
  lumis: PropTypes.number,
  size: PropTypes.string,
};

export default UserProfile;
