const SVGBucketStatus = (props) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.6887 6.49609L17.1616 21.6591L16.8488 21.8155C15.1004 22.69 13.1723 23.1452 11.2174 23.1452C9.26246 23.1452 7.33438 22.69 5.58595 21.8155L5.27314 21.6591L2.74609 6.49758L19.6887 6.49609Z"
      fill="#742FCD"
    />
    <path
      d="M11.2171 9.02842C8.04877 9.02842 4.71814 8.54285 2.91406 7.50781L5.27281 21.6599L5.58563 21.8163C7.14936 22.5996 8.85948 23.0476 10.6064 23.1316C12.3533 23.2156 14.0985 22.9338 15.7302 22.3041L17.5031 11.6662C17.5671 11.2902 17.5426 10.9045 17.4317 10.5397C17.3208 10.1748 17.1263 9.84076 16.8639 9.56407C16.6014 9.28738 16.2782 9.07561 15.9196 8.94556C15.5611 8.81551 15.1773 8.77074 14.7985 8.8148C13.6101 8.95856 12.4141 9.02989 11.2171 9.02842Z"
      fill="#7E32E1"
    />
    <path
      d="M20.1335 3.45703V5.68612C20.1335 7.1224 16.1416 8.28673 11.2171 8.28673C6.29271 8.28673 2.30078 7.1224 2.30078 5.68612V3.45703H20.1335Z"
      fill="#9747FF"
    />
    <path
      d="M11.2171 6.05668C16.1415 6.05668 20.1335 4.89235 20.1335 3.45607C20.1335 2.0198 16.1415 0.855469 11.2171 0.855469C6.29277 0.855469 2.30078 2.0198 2.30078 3.45607C2.30078 4.89235 6.29277 6.05668 11.2171 6.05668Z"
      fill="#742FCD"
    />
    <path
      d="M18.9229 11.0859C20.1027 12.3769 21.1415 13.79 22.0217 15.3011C22.3002 15.7779 22.4129 16.3335 22.3422 16.8811C22.2714 17.4287 22.0212 17.9374 21.6306 18.3277C21.2401 18.718 20.7312 18.9679 20.1836 19.0383C19.6359 19.1087 19.0804 18.9957 18.6038 18.7169C15.8462 17.1112 13.4282 14.9832 11.4852 12.452L11.1367 11.9988L12.3122 11.0897L12.6629 11.5459C14.4896 13.9248 16.7625 15.9247 19.3546 17.4336C19.5476 17.5463 19.7725 17.5918 19.9941 17.5632C20.2157 17.5346 20.4216 17.4334 20.5796 17.2754C20.7377 17.1175 20.839 16.9116 20.8677 16.69C20.8965 16.4684 20.851 16.2435 20.7385 16.0505C20.1111 14.9738 19.398 13.9495 18.606 12.9874L18.9229 11.0859Z"
      fill="#95AFD1"
    />
    <path
      d="M3.16016 4.572C4.58937 5.45026 7.66069 6.05806 11.2179 6.05806C14.7752 6.05806 17.8447 5.45026 19.2754 4.572C17.8443 3.69336 14.7741 3.08594 11.2179 3.08594C7.6618 3.08594 4.59123 3.69336 3.16016 4.572Z"
      fill="#66C3E9"
    />
    <path
      d="M7.6184 3.30669C7.36537 3.3382 7.13236 3.46044 6.96261 3.65072C6.79286 3.841 6.69791 4.08639 6.69536 4.34137C6.69282 4.59635 6.78287 4.84359 6.94879 5.03721C7.11471 5.23083 7.34524 5.35769 7.5976 5.39424C8.95783 5.59369 10.331 5.69143 11.7058 5.68662C14.8265 5.68662 17.5735 5.21851 19.1662 4.50966C17.6932 3.66521 14.6898 3.08601 11.2188 3.08601C10.0152 3.08321 8.81263 3.15692 7.6184 3.30669Z"
      fill="#8ADEF9"
    />
    <path
      d="M18.6044 17.6047C18.9422 17.8021 19.3217 17.9172 19.7122 17.9408C20.1027 17.9645 20.4933 17.896 20.8525 17.7409C21.2116 17.5858 21.5293 17.3484 21.7798 17.0479C22.0303 16.7473 22.2067 16.3921 22.2946 16.0109C22.2398 15.7631 22.1475 15.525 22.0208 15.3051C21.1406 13.7939 20.1018 12.3808 18.922 11.0898L18.6051 12.9913C19.397 13.9534 20.1102 14.9777 20.7376 16.0544C20.8501 16.2475 20.8955 16.4723 20.8668 16.6939C20.8381 16.9155 20.7368 17.1214 20.5787 17.2793C20.4207 17.4373 20.2148 17.5385 19.9932 17.5671C19.7716 17.5957 19.5467 17.5502 19.3537 17.4375C16.7615 15.9282 14.4885 13.9277 12.662 11.5483L12.3113 11.0921L11.6797 11.581C13.5884 14.0067 15.9377 16.0504 18.6044 17.6047Z"
      fill="#A5BADB"
    />
    <path
      d="M11.2161 12.0025C11.8317 12.0025 12.3307 11.5035 12.3307 10.888C12.3307 10.2724 11.8317 9.77344 11.2161 9.77344C10.6006 9.77344 10.1016 10.2724 10.1016 10.888C10.1016 11.5035 10.6006 12.0025 11.2161 12.0025Z"
      fill="#A5BADB"
    />
    <path
      d="M11.2168 9.77346C11.0126 9.77414 10.8126 9.8315 10.6391 9.93916C10.5073 10.1518 10.4512 10.4028 10.48 10.6513C10.5089 10.8999 10.6209 11.1314 10.7979 11.3082C10.975 11.485 11.2066 11.5968 11.4552 11.6253C11.7038 11.6538 11.9547 11.5975 12.1672 11.4653C12.2714 11.2969 12.3286 11.1035 12.3328 10.9055C12.337 10.7074 12.288 10.5118 12.191 10.3391C12.094 10.1664 11.9524 10.0228 11.7811 9.92334C11.6098 9.82386 11.4149 9.77211 11.2168 9.77346Z"
      fill="#C1CFE8"
    />
  </svg>
);
export default SVGBucketStatus;
