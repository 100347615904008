import { getCookies } from '../lib/cookies';
import { getAuthenticatedUser } from './user';

export const getLocale = () => {
  const lang = getCookies('lang');
  if (!lang) {
    return getAuthenticatedUser().language || 'en';
  }
  return lang;
};
