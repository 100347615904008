import styled from 'styled-components';
import { maxWidthQuery, minWidthQuery } from '../../../utils';
import Button from '../../Button/styles';
import { footerNavigationBarVisibleBreakpoint } from '../constants';

type Props = {
  changeOnMobile?: boolean;
  hiddenLogo?: any;
};

export const Logo = styled.div<Props>`
  svg {
    width: 107px;
    height: auto;
    margin-right: 39px;

    ${maxWidthQuery('sm')(`
      width: 100%;
      max-width: 81px;
      height: auto;
    `)}

    ${(props) =>
      maxWidthQuery('md')(`
      ${props.changeOnMobile && 'max-width: 96.72px; width: 96.72px; margin: 0; height: 33px;'}

      margin-right: 39px;
    `)};

    @media (max-width: ${footerNavigationBarVisibleBreakpoint}px) {
      margin-right: unset;
    }
  }
`;

export const HeaderContent = styled.header<Props>`
  display: flex;
  align-items: center;
  h2 {
    position: absolute;
    margin: 0;
    text-indent: -3333px;
  }
  ${(props) =>
    maxWidthQuery('md')(`
    ${props.changeOnMobile && 'justify-content: space-between; width: 100%;flex-direction: row-reverse;'}
  `)};

  ${maxWidthQuery('sm')(`
    > a:first-child {
      left: 0px;
      position: relative;
    }
  `)};
`;
export const NotificationContent = styled.div<Props>`
  width: 44px;
  margin-right: 32px;
  margin-top: 3px;
  position: relative;
  cursor: pointer;
  display: inline-block;

  p {
    position: absolute;
    top: -3px;
    right: -3px;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.04em;
    color: #fcfafb;
    width: 18px;
    height: 18px;
    background: #fc6453;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(252, 100, 83, 0.12), 0px 4px 8px rgba(252, 100, 83, 0.18);
    border-radius: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ${(props) =>
    maxWidthQuery('md')(`
    ${props.changeOnMobile && 'margin: 0;'}
  `)};
`;

export const HeaderContentNav = styled.nav<Props>`
  display: flex;
  justify-content: flex-end;

  ${(props) =>
    props.hiddenLogo &&
    `
    justify-content: space-between;
  `};

  ${(props) =>
    minWidthQuery('md')(`
    ${
      props.hiddenLogo &&
      `
        justify-content: flex-end;
        > a:last-of-type,
        > div:last-of-type {
        }
    `
    };
  `)}

  ${Button}:not(:last-of-type) {
    ${(props) =>
      minWidthQuery('sm')(`
      margin-right: ${props.theme.layout.small};
    `)}
  }
`;

export const MainHeaderLinks = styled.div`
  display: flex;
  align-items: center;
  color: #675f72;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.01em;
  margin-right: 30px;
  cursor: pointer;
  white-space: nowrap;
  svg {
    margin-right: 10.5px;
  }
  .home path,
  .start path {
    stroke: #675f72;
  }

  &.active {
    color: #fc6453;
    .home path,
    .start path {
      stroke: #fc6453;
    }
  }
`;
