import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { pageRoutes } from '../const/routes';
import { ErrorMessages, PageRoutes } from '../constants';
import { getCookies, removeCookies } from './cookies';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  config => {
    if (config.url && config.url.includes('auth')) {
      return config;
    }
    const jwtToken = getCookies('jwt');
    config.headers.Authorization = `Bearer ${jwtToken}`;
    return config;
  },
  (error: AxiosError) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(res => {
  if (res.status === StatusCodes.INTERNAL_SERVER_ERROR) {
    throw new Error(ErrorMessages.INTERNAL_SERVER_ERROR);
  }
  if (
    res.status === StatusCodes.UNAUTHORIZED &&
    (window.location.pathname !== PageRoutes.LOGIN && window.location.pathname !== pageRoutes.PRIVACY_PATH)
  ) {
    removeCookies(['user', 'jwt', 'role', 'lang', 'white_label']);
    window.location.href = PageRoutes.LANDING;
  }
  return res.data;
});

export const axiosBaseQuery = (
  { baseUrl }: { baseUrl: string } = { baseUrl: '' },
): BaseQueryFn<
  {
    url: string;
    method?: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    headers?: AxiosRequestConfig['headers'];
  },
  unknown,
  unknown
> => async ({ url, method, data, params, headers, transformResponse }: Partial<AxiosRequestConfig>) => {
  try {
    const response = await axiosInstance({
      url: baseUrl + url,
      method,
      data,
      params,
      headers,
      transformResponse,
    });
    return { data: response };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    return {
      error: {
        status: err.response && err.response.status,
        data: err.response ? err.response.data : err.message,
      },
    };
  }
};
