import PropTypes from 'prop-types';
import { ButtonIcon, ButtonWrapper } from './styles';

export const Button = (props) => {
  const { icon, postIcon, onClick } = props;

  return (
    <ButtonWrapper {...props} onClick={onClick}>
      {props.icon && <ButtonIcon>{icon}</ButtonIcon>}
      {props.children}
      {props.postIcon && <ButtonIcon post>{postIcon}</ButtonIcon>}
    </ButtonWrapper>
  );
};

Button.propTypes = {
  icon: PropTypes.element,
  postIcon: PropTypes.element,
  boxShadow: PropTypes.string,
  fontWeight: PropTypes.string,
  marginRight: PropTypes.string,
  styles: PropTypes.string,
  size: PropTypes.string,
  textTransform: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  hoverColorBorder: PropTypes.bool,
  mixBlendMode: PropTypes.string,
  width: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
