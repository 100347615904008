const ActionTypes = {
  SHOW_SESSION: 'SHOW_SESSION',
  SHOW_SESSION_NOT_FOUND: 'SHOW_SESSION_NOT_FOUND',
  SEND_REPORT_SESSION: 'SEND_REPORT_SESSION',
  SET_FAVORITE_SESSION: 'SET_FAVORITE_SESSION',
  SEND_RECOMMENDATION_SESSION: 'SEND_RECOMMENDATION_SESSION',
  SEND_RECOMMENDATION_PROGRAM: 'SEND_RECOMMENDATION_PROGRAM',
  UPDATE_PROGRAM_MODAL: 'UPDATE_PROGRAM_MODAL',
  SAVE_CARD_SESSION: 'SAVE_CARD_SESSION',
  INDEX_SESSIONS: 'INDEX_SESSIONS',
  INDEX_SESSIONS_FORHOME: 'INDEX_SESSIONS_FORHOME',
  INDEX_SESSIONS_FAVORITES: 'INDEX_SESSIONS_FAVORITES',
  INDEX_SESSIONS_WITHFILTER: 'INDEX_SESSIONS_WITHFILTER',
  INDEX_SESSIONS_FOR_YOU: 'INDEX_SESSIONS_FOR_YOU',
  DATA_SESSIONS: 'DATA_SESSIONS',
  FILTER_BY_NEED_SESSIONS: 'FILTER_BY_NEED_SESSIONS',
  FILTER_BY_NEED_GOAL_SESSIONS: 'FILTER_BY_NEED_GOAL_SESSIONS',
  INDEX_NEEDS: 'INDEX_NEEDS',
  EXCLUDE_FORYOU_NEEDS_API: 'EXCLUDE_FORYOU_NEEDS_API',
  INDEX_NEEDS_WITHFILTER: 'INDEX_NEEDS_WITHFILTER',
  SHOW_GOAL: 'SHOW_GOAL',
  SHOW_GOAL_NOT_FOUND: 'SHOW_GOAL_NOT_FOUND',
  CREATE_GOAL: 'CREATE_GOAL',
  CREATE_GOAL_ERROR: 'CREATE_GOAL_ERROR',
  INDEX_FROM_USER_ACTIONS: 'INDEX_FROM_USER_ACTIONS',
  UPDATE_FROM_USER_ACTIONS: 'UPDATE_FROM_USER_ACTIONS',
  DELETE_FROM_USER_ACTIONS: 'DELETE_FROM_USER_ACTIONS',
  UPDATE_ALL_FROM_USER_ACTIONS: 'UPDATE_ALL_FROM_USER_ACTIONS',
  SHOW_ACTIVITY_ANSWER: 'SHOW_ACTIVITY_ANSWER',
  INDEX_GOAL_SESSIONS: 'INDEX_GOAL_SESSIONS',
  INDEX_FROM_GOAL_ACTIONS: 'INDEX_FROM_GOAL_ACTIONS',
  UPDATE_FROM_GOAL_ACTIONS: 'UPDATE_FROM_GOAL_ACTIONS',
  DELETE_FROM_GOAL_ACTIONS: 'DELETE_FROM_GOAL_ACTIONS',
  UPDATE_ALL_FROM_GOAL_ACTIONS: 'UPDATE_ALL_FROM_GOAL_ACTIONS',
  INDEX_GOALS: 'INDEX_GOALS',
  INDEX_COACHES: 'INDEX_COACHES',
  DELETE_GOAL: 'DELETE_GOAL',
  DELETE_GOAL_ERROR: 'DELETE_GOAL_ERROR',
  UPDATE_GOAL: 'UPDATE_GOAL',
  UPDATE_GOAL_ERROR: 'UPDATE_GOAL_ERROR',
  INDEX_FROM_GOAL_JOURNALING: 'INDEX_FROM_GOAL_JOURNALING',
  CREATE_ENTRY_FOR_GOAL_JOURNALING: 'CREATE_ENTRY_FOR_GOAL_JOURNALING',
  CREATE_ENTRY_FOR_USER_JOURNALING: 'CREATE_ENTRY_FOR_USER_JOURNALING',
  UPDATE_ENTRY_JOURNALING: 'UPDATE_ENTRY_JOURNALING',
  DELETE_ENTRY_JOURNALING: 'DELETE_ENTRY_JOURNALING',
  CREATE_EVENT_LOG: 'CREATE_ENTRY_FOR_GOAL_JOURNALING',
  UPDATE_EVENT_LOG: 'UPDATE_ENTRY_JOURNALING',
  DELETE_EVENT_LOG: 'DELETE_ENTRY_JOURNALING',
  LOGIN_ADMIN: 'LOGIN_ADMIN',
  LOGIN_ADMIN_ERROR: 'LOGIN_ADMIN_ERROR',
  LOGIN_USER: 'LOGIN_USER',
  LOGIN_USER_ERROR: 'LOGIN_USER_ERROR',
  REGISTER_USER: 'REGISTER_USER',
  REGISTER_USER_ERROR: 'REGISTER_USER_ERROR',
  USER_PROFILE: 'USER_PROFILE',
  USER_PROFILE_ERROR: 'USER_PROFILE_ERROR',
  GET_ONGOING_PROGRAMS: 'GET_ONGOING_PROGRAMS_',
  GET_ONGOING_PROGRAMS_ERROR: 'GET_ONGOING_PROGRAMS_ERROR',
  SHOW_USER: 'SHOW_USER',
  SHOW_USER_ERROR: 'SHOW_USER_ERROR',
  AUTH_INVALID_USER: 'AUTH_INVALID_USER',
  INDEX_USERS: 'INDEX_USERS',
  SEARCH_USER: 'SEARCH_USER',
  CREATE_USER: 'CREATE_USER',
  CREATE_USER_ERROR: 'CREATE_USER_ERROR',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',
  DELETE_USER: 'DELETE_USER',
  DELETE_USER_ERROR: 'DELETE_USER_ERROR',
  EDIT_PASSWORD_USER: 'EDIT_PASSWORD_USER',
  EDIT_PASSWORD_USER_ERROR: 'EDIT_PASSWORD_USER_ERROR',
  RESET_PASSWORD_USER: 'RESET_PASSWORD_USER',
  RESET_PASSWORD_USER_ERROR: 'RESET_PASSWORD_USER_ERROR',
  RECOVER_PASSWORD_USER: 'RECOVER_PASSWORD_USER',
  RECOVER_PASSWORD_USER_ERROR: 'RECOVER_PASSWORD_USER_ERROR',
  INVITE_USER: 'INVITE_USER',
  INVITE_USER_ERROR: 'INVITE_USER_ERROR',
  RESET_PASSWORD_USER_EMAIL: 'RESET_PASSWORD_USER_EMAIL',
  RESET_PASSWORD_USER_EMAIL_ERROR: 'RESET_PASSWORD_USER_EMAIL_ERROR',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  UPLOAD_PICTURE_PROFILE: 'UPLOAD_PICTURE_PROFILE',
  SHOW_PROFILE: 'SHOW_PROFILE',
  SHOW_PROFILE_SESSIONS: 'SHOW_PROFILE_SESSIONS',
  SHOW_PROFILE_SKILLS: 'SHOW_PROFILE_SKILLS',
  SHOW_PROFILE_GOALS: 'SHOW_PROFILE_GOALS',
  SHOW_PROFILE_ACTIONS: 'SHOW_PROFILE_ACTIONS',
  SEND_CONTACT: 'SEND_CONTACT',
  SEND_MESSAGE_HOME_API: 'SEND_MESSAGE_HOME_API',
  SEND_CONTACT_ERROR: 'SEND_CONTACT_ERROR',
  SEND_FEEDBACK: 'SEND_FEEDBACK',
  SEND_FEEDBACK_ERROR: 'SEND_FEEDBACK_ERROR',
  REQUEST_FAILED: 'REQUEST_FAILED',
  EXPORT_USERS: 'EXPORT_USERS',
  GET_ANALYTICS: 'GET_ANALYTICS',
  GET_ANALYTICS_FILTERS: 'GET_ANALYTICS_FILTERS',
  INDEX_FROM_NOTIFICATIONS: 'INDEX_FROM_NOTIFICATIONS',
  INDEX_FROM_USER_NOTIFICATIONS: 'INDEX_FROM_USER_NOTIFICATIONS',
  UPDATE_USER_NOTIFICATIONS: 'UPDATE_USER_NOTIFICATIONS',
  CREATE_USER_NOTIFICATIONS: 'CREATE_USER_NOTIFICATIONS',
  SHOW_REWARDS: 'SHOW_REWARDS',
  INDEX_REWARDS: 'INDEX_REWARDS',
  ADD_REWARD_ACTION: 'ADD_REWARD_ACTION',
  SEND_SESSION_OPINION_API: 'SEND_SESSION_OPINION_API',
  SESSION_OPINIONS_API: 'SESSION_OPINIONS_API',
  EXPORT_SESSION_OPINIONS: 'EXPORT_SESSION_OPINIONS',
  WITH_FILTERS_NEEDS_API: 'WITH_FILTERS_NEEDS_API',
  INDEX_FILTERS: 'INDEX_FILTERS',
  SHOW_ARTICLE: 'SHOW_ARTICLE',
  SHOW_ARTICLE_NOT_FOUND: 'SHOW_ARTICLE_NOT_FOUND',
  INDEX_PROGRAMS: 'INDEX_PROGRAMS',
  SHOW_PROGRAM: 'SHOW_PROGRAM',
  SHOW_USER_PROGRAM: 'SHOW_USER_PROGRAM',
  SHOW_PROGRAM_NOT_FOUND: 'SHOW_PROGRAM_NOT_FOUND',
  CREATE_PROGRAM: 'CREATE_PROGRAM',
  CREATE_PROGRAM_ERROR: 'CREATE_PROGRAM_ERROR',
  UPDATE_PROGRAM_ERROR: 'UPDATE_PROGRAM_ERROR',
  UPDATE_PROGRAM: 'UPDATE_PROGRAM',
  GET_PROGRAM_ACTIONS: 'GET_PROGRAM_ACTIONS',
  INDEX_FROM_PROGRAM_JOURNALING: 'INDEX_FROM_PROGRAM_JOURNALING',
  DELETE_FROM_PROGRAM_ACTIONS: 'DELETE_FROM_PROGRAM_ACTIONS',
  SHOW_PROFILE_PROGRAMS: 'SHOW_PROFILE_PROGRAMS',
  CREATE_ENTRY_FOR_PROGRAM_JOURNALING: 'CREATE_ENTRY_FOR_PROGRAM_JOURNALING',
  GET_MENTORS: 'GET_MENTORS',
  ADD_MENTOR: 'ADD_MENTOR',
  INDEX_NOTES: 'INDEX_NOTES',
  CREATE_NOTE: 'CREATE_NOTE',
  CREATE_NOTE_ERROR: 'CREATE_NOTE_ERROR',
  UPDATE_NOTE: 'UPDATE_NOTE',
  UPDATE_NOTE_ERROR: 'UPDATE_NOTE_ERROR',
  SEND_ARTICLE_OPINION_API: 'SEND_ARTICLE_OPINION_API',
  ARTICLE_OPINIONS_API: 'ARTICLE_OPINIONS_API',
  EXPORT_ARTICLE_OPINIONS: 'EXPORT_ARTICLE_OPINIONS',
  SEND_PROGRAM_RATING_API: 'SEND_PROGRAM_RATING_API',
  PROGRAM_RATINGS_API: 'PROGRAM_RATINGS_API',
  PROGRAM_SEARCH: 'PROGRAM_SEARCH',
  PROGRAM_SEARCH_ERROR: 'PROGRAM_SEARCH_ERROR',
  GET_PROGRAM: 'GET_PROGRAM',
  SEND_PROGRAM_RATING_ERROR: 'SEND_PROGRAM_RATING_ERROR',
  EXPORT_PROGRAMS_RATINGS: 'EXPORT_PROGRAMS_RATINGS',
  GET_USER_SETTINGS: 'GET_USER_SETTINGS',
  UPDATE_USER_SETTINGS: 'UPDATE_USER_SETTINGS',
  GET_USER_LOCKED_CONTENT_FEEDBACKS: 'GET_USER_LOCKED_CONTENT_FEEDBACKS',
  GET_USER_LOCKED_CONTENT_FEEDBACK_STATUS: 'GET_USER_LOCKED_CONTENT_FEEDBACK_STATUS',
  ADD_USER_LOCKED_CONTENT_FEEDBACK: 'ADD_USER_LOCKED_CONTENT_FEEDBACK',
  INDEX_FROM_USER_GOAL_ACTIONS: 'INDEX_FROM_USER_GOAL_ACTIONS',
  UPDATE_FROM_USER_GOAL_ACTIONS: 'UPDATE_FROM_USER_GOAL_ACTIONS',
  DELETE_USER_GOAL_ACTION: 'DELETE_USER_GOAL_ACTION',
  USER_GOAL_ACTIONS_NOT_FOUND: 'USER_GOAL_ACTIONS_NOT_FOUND',
  UPDATE_USER_GOAL_ACTION_ERROR: 'UPDATE_USER_GOAL_ACTION_ERROR',
};

export default ActionTypes
