/* ==========================================================================
  Grid
========================================================================== */
const grid = {
  mediaQuery: 'only screen',
  gridSize: {
    xs: 6,
    sm: 6,
    md: 12,
    lg: 12,
  },
  gutterWidth: {
    xs: 16,
    sm: 16,
    md: 16,
    lg: 16,
  },
  outerMargin: {
    xs: 24,
    sm: 24,
    md: 40,
    lg: 64,
  },
  container: {
    sm: 530,
    md: 754,
    lg: 898,
  },
  breakpoints: {
    xs: 0,
    sm: 578,
    md: 834,
    lg: 1200,
    xlg: 1800,
  },
};

/* ==========================================================================
  Utils with helper functions for CSS
========================================================================== */

const utils = {
  WorkSans() {
    return `
      'Work Sans', sans-serif;
    `;
  },
  IBMPlexSerif() {
    return `
      'IBM Plex Serif', serif;
    `;
  },
  rem(fontSize, browserContext = 16) {
    return `${fontSize / browserContext}rem`;
  },
  hide() {
    return `
      display: none !important;
    `;
  },
  show() {
    return `
      display: block !important;
    `;
  },
  truncate(width) {
    return `
      width: ${width / 16}rem;
      max-width: ${width / 16}rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `;
  },
  removeTruncate() {
    return `
      width: 100%;
      max-width: 100%;
      white-space: normal;
    `;
  },
  fullWidthWithoutScrollbar() {
    return 'calc(100vw - (100vw - 100%))';
  },
  centered() {
    return `
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    `;
  },
  horizontalyCentered() {
    return `
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    `;
  },
  verticalCentered() {
    return `
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    `;
  },
  withOuterPadding() {
    return `
      padding-right: ${utils.rem(grid.outerMargin.xs)};
      padding-left: ${utils.rem(grid.outerMargin.xs)};

      @media (min-width: ${grid.breakpoints.sm}px) {
        padding-right: ${utils.rem(grid.outerMargin.sm)};
        padding-left: ${utils.rem(grid.outerMargin.sm)};
      }
      @media (min-width: ${grid.breakpoints.md}px) {
        padding-right: ${utils.rem(grid.outerMargin.md)};
        padding-left: ${utils.rem(grid.outerMargin.md)};
      }
      @media (min-width: ${grid.breakpoints.lg}px) {
        padding-right: ${utils.rem(grid.outerMargin.lg)};
        padding-left: 6.5rem;
      }
    `;
  },
  withOuterPaddingLibrary() {
    return `
      padding-right: 1rem;
      padding-left: 1rem;

      @media (min-width: ${grid.breakpoints.sm}px) {
        padding-right: ${utils.rem(grid.outerMargin.sm)};
        padding-left: ${utils.rem(grid.outerMargin.sm)};
      }
      @media (min-width: ${grid.breakpoints.md}px) {
        padding-right: ${utils.rem(grid.outerMargin.md)};
        padding-left: ${utils.rem(grid.outerMargin.md)};
      }
      @media (min-width: ${grid.breakpoints.lg}px) {
        padding-right: ${utils.rem(grid.outerMargin.lg)};
        padding-left: 6.5rem;
      }
    `;
  },
  withOuterWidth(padding = null) {
    return `
      width: calc(100vw - (${utils.rem(grid.outerMargin.xs)} * 2 + ${padding || 0}px));

      @media (min-width: ${grid.breakpoints.sm}px) {
        width: calc(100vw - (${utils.rem(grid.outerMargin.sm)} * 2 + ${padding || 0}px));
      }
      @media (min-width: ${grid.breakpoints.md}px) {
        width: calc(100vw - (${utils.rem(grid.outerMargin.md)} * 2 + ${padding || 0}px));
      }
      @media (min-width: ${grid.breakpoints.lg}px) {
        width: calc(100vw - (${utils.rem(grid.outerMargin.lg)} * 2 + ${padding || 0}px));
      }
    `;
  },
  toSeconds(miliseconds) {
    return miliseconds / 1000;
  },
};

/* ==========================================================================
  Colors
  http://chir.ag/projects/name-that-color
========================================================================== */
const colors = {
  white: '#FFFFFF',
  pampas: '#F9F7F5',
  pampas2: '#F8F3EE',
  pampas3: '#EFEBE8',
  softPeach: '#FCFAFB',
  springOfWood: '#FCFBF9',
  hintOfRed: '#FCFBFC',
  hintOfRed2: '#F9F7F5',
  hintOfRed3: 'rgba(252,251,252,0.7)',
  athensGray: '#F2F1F5',
  vanillaIce: '#F3DBD6',
  martini: '#AAA0A0',
  mamba: '#8C8694',
  silver: '#C2C2C2',
  dustyGray: '#979797',
  mountainMist: '#A29BA4',
  merino: '#F9F5EF',
  saltBox: '#675F72',
  martinique: '#3C3958',
  portGore: '#211E42',
  portGore2: '#1E2142',
  blueDianne: '#1D3F49',
  guardsmanRed: '#C90000',
  alizarinCrimson: '#ED2939',
  persimmon: '#FC6453',
  vividTangerine: '#FE9486',
  givry: '#F9D8D0',
  mandy: '#E86252',
  creamCan: '#f5d25d',
  fantasy: '#f9f7f3',
  terracotta: '#ff7864',
};

/* ==========================================================================
  Animations
========================================================================== */
let animations = {
  fast: 90,
  normal: 180,
  slow: 350,
  default: 600,
  extraSlow: 650,
  long: 900,
};

animations = {
  ...animations,
  fastInSeconds: utils.toSeconds(animations.fast),
  normalInSeconds: utils.toSeconds(animations.normal),
  slowInSeconds: utils.toSeconds(animations.slow),
  defaultInSeconds: utils.toSeconds(animations.default),
  extraSlowInSeconds: utils.toSeconds(animations.extraSlow),
  longInSeconds: utils.toSeconds(animations.default),
};

/* ==========================================================================
  Typography
========================================================================== */
const typography = {
  browserContext: utils.rem(16),
};

/* ==========================================================================
  Layout
========================================================================== */
const layout = {
  micro: utils.rem(8),
  tiny: utils.rem(16),
  small: utils.rem(24),
  medium: utils.rem(40),
  large: utils.rem(64),
  xl: utils.rem(104),
  xxl: utils.rem(168),
  xl3: utils.rem(264),
  xl4: utils.rem(424),
  xl5: utils.rem(680),
  xl6: utils.rem(1088),
};

const sections = {
  xsCardDescriptionMaxHeight: 36,
  lgCardDescriptionMaxHeight: 46,
  xsHeaderHeight: 82,
  mdHeaderHeight: 88,
  lgQuestionWidth: grid.container.lg,
  mdModalWidth: 706,
  xsGradeWidth: 300,
  smGradeWidth: 325,
  lgGradeWidth: 400,
  itemsMaxHeight: 80,
  xsSidebarInitialHeight: 64,
  mdSidebarInitialWidth: 64,
  mdSidebarFullWidth: 356,
  feedbackFormHeight: 180,
};

export default {
  grid,
  colors,
  animations,
  typography,
  layout,
  sections,
  utils,
};
