export const getWindowHash = () => {
  return window.location.hash.replace('#', '');
};

export const setWindowHash = value => {
  window.location.replace('#' + value);
  if (value === '') {
    window.history.replaceState({}, document.title, window.location.href.substring(0, window.location.href.indexOf('#')));
  }
};
