import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../../services/apiClient';

const initialState = {
  data: {},
  status: 'idle',
  error: null,
};

export const fetchProgramIntroduction = createAsyncThunk('userProgram/fetchProgramIntroduction', async userProgramId => {
  const { data } = await apiClient.get(`/user-program-answers/${userProgramId}/introduction`);
  return data;
});

// TODO: We should fetch user-step-answer here
export const fetchProgramStep = createAsyncThunk('userProgram/fetchProgramStep', async ({userProgramId, programStepId}) => {
  const { data } = await apiClient.get(`/program-steps/${programStepId}/answer`, {
    params: {
      userProgramId
    }
  });
  return data;
});

export const fetchProgramConclusion = createAsyncThunk('userProgram/fetchProgramConclusion', async userProgramId => {
  const { data } = await apiClient.get(`/user-program-answers/${userProgramId}/conclusion`);
  return data;
});

const programStepsSlice = createSlice({
  name: 'programStepsSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchProgramIntroduction.pending]: state => {
      state.status = 'loading';
    },
    [fetchProgramIntroduction.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data.introduction = action.payload;
    },
    [fetchProgramIntroduction.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    [fetchProgramStep.pending]: state => {
      state.status = 'loading';
    },
    [fetchProgramStep.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data.step = action.payload;
    },
    [fetchProgramStep.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    [fetchProgramConclusion.pending]: state => {
      state.status = 'loading';
    },
    [fetchProgramConclusion.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data.conclusion = action.payload;
    },
    [fetchProgramConclusion.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default programStepsSlice.reducer;
