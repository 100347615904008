import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { getLocale } from '../utils';
import en from './en.json';
import fr from './fr.json';
import pt from './pt.json';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: getLocale(),
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
      pt: {
        translation: pt,
      },
    },
    // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
    // set returnNull to false (and also in the i18next.d.ts options)
    // returnNull: false,
  });

export default i18next;
