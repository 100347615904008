import styled from 'styled-components';
import { maxWidthQuery } from '../../../utils';

type Props = {
  sessionCompleted?: boolean;
};

export const SessionTitle = styled.span`
  margin-right: ${props => props.theme.layout.micro};
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #1e2142;
  ${maxWidthQuery('sm')(`
    font-size: 14px;
  `)}
`;

export const SessionNeed = styled.span<Props>`
  line-height: 20px;
  letter-spacing: -0.03em;
  font-weight: ${props => (props.sessionCompleted ? '500' : '400')};
  font-size: ${props => (props.sessionCompleted ? '11px' : '14px')};
  line-height: 1;
  color: ${props => (props.sessionCompleted ? '#fc6453' : '#8a8390')};
  text-transform: ${props => (props.sessionCompleted ? 'uppercase' : 'capitalize')};
`;

export const SessionTopic = styled.span<Props>`
  font-size: ${props => (props.sessionCompleted ? '11px' : '14px')};
  line-height: 20px;
  font-weight: ${props => (props.sessionCompleted ? '500' : '400')};
  color: ${props => (props.sessionCompleted ? '#fc6453' : '#8A8390')};
  text-transform: ${props => props.sessionCompleted && 'uppercase'};
`;
