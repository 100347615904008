import types from './actions/types';
import initialState from './initialState';

export default (state = initialState.userGoalActions, action) => {
  switch (action.type) {
    case types.INDEX_FROM_USER_GOAL_ACTIONS:
    case types.UPDATE_FROM_USER_GOAL_ACTIONS:
      return {
        ...state,
        data: action.payload.data
      };
    case types.DELETE_USER_GOAL_ACTION: {
      return {
        ...state,
        data: state.data.filter(
          userGoalAction => userGoalAction.id !== action.options.id
        ),
      };
    }
    default:
      return state;
  }
};
