import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { PageRoutes } from '../../constants';
import { ReactComponent as Home } from '../../images/home.svg';
import { ReactComponent as Start } from '../../images/play-header.svg';
import { ReactComponent as Programs } from '../../images/programs.svg';
import MediaQuery from '../../services/mediaQuery';
import { SessionHeader } from '../Header/Session';
import { DefaultHeader } from './DefaultHeader';
import { HeaderWrapper, MobileFooter } from './styled';
import classNames from 'classnames';
import { ExtendedUserProfile } from '../../types';
import { footerNavigationBarVisibleBreakpoint } from './constants';
import { CheckListIcon } from 'hugeicons-react';

type Props = {
  className: string;
  loggedInUser: ExtendedUserProfile;
  headerNavButtonsWithHeaderSticky: any;
  notificationsCount: number;
  changeOnMobile: boolean;
  hide: boolean;
  hideMobileFooter: boolean;
  newLayout: boolean;
  noHeaderBkg: boolean;
  notifications: boolean;
  showHeaderActions: boolean;
  sticky: boolean;
  withScrolledBg: boolean;
  openNotification: () => void;
};

export function Header({
  children,
  className,
  loggedInUser,
  headerNavButtonsWithHeaderSticky,
  changeOnMobile,
  hide,
  hideMobileFooter,
  newLayout,
  noHeaderBkg,
  notifications,
  notificationsCount,
  showHeaderActions,
  sticky,
  withScrolledBg,
  openNotification,
}: PropsWithChildren<Partial<Props>>) {
  const { t } = useTranslation();
  const location = useLocation();

  const [width, setWidth] = useState(0);
  const [state, setState] = useState({
    isScrolling: false,
    isMobile: false,
    showInsightLocationModal: false,
  });

  const isScrolling = () => (document.documentElement.scrollTop || document.body.scrollTop) > 0;

  const addScrollingToHeader = useCallback(() => {
    setState({ ...state, isScrolling: isScrolling() });
  }, [state]);

  const changeWidth = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    changeWidth();
    window.addEventListener('resize', changeWidth);
    return () => {
      window.removeEventListener('resize', changeWidth);
    };
  }, [changeWidth]);

  useEffect(() => {
    if (sticky && isScrolling()) {
      setState({
        ...state,
        isScrolling: true,
      });
    }
    setState({ ...state, isMobile: MediaQuery.isMobile() });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sticky]);

  useEffect(() => {
    window.addEventListener('scroll', addScrollingToHeader, true);

    return () => {
      window.removeEventListener('scroll', addScrollingToHeader, true);
    };
  }, [addScrollingToHeader]);

  const showOnMobile = () => {
    return (
      location.pathname === PageRoutes.HOME ||
      location.pathname === PageRoutes.SESSIONS_LIBRARY ||
      location.pathname === PageRoutes.PROGRAMS_LIBRARY ||
      location.pathname === PageRoutes.TO_DOS_LIBRARY ||
      location.pathname === PageRoutes.PROFILE ||
      location.pathname === PageRoutes.INSIGHTS
    );
  };

  return (
    <>
      <HeaderWrapper
        className={className}
        sticky={sticky}
        withScrolledBg={withScrolledBg}
        scrolled={state.isScrolling}
        hide={hide}
        noHeaderBkg={noHeaderBkg}
        newLayout={newLayout}
      >
        {children ? (
          <SessionHeader data={children} notifications={notifications} />
        ) : (
          <DefaultHeader
            changeOnMobile={changeOnMobile}
            isMobile={state.isMobile}
            isScrolling={state.isScrolling}
            loggedInUser={loggedInUser}
            headerNavButtonsWithHeaderSticky={headerNavButtonsWithHeaderSticky}
            showHeaderActions={showHeaderActions}
          />
        )}
      </HeaderWrapper>

      {changeOnMobile && !hideMobileFooter && (width && width <= footerNavigationBarVisibleBreakpoint) && showOnMobile() && (
        <MobileFooter>
          <div className="list">
            <Link className={classNames({ active: location.pathname === PageRoutes.HOME })} to={PageRoutes.HOME}>
              <Home />
              <p>{t('menu_dashboard')}</p>
            </Link>
            <Link className={location.pathname === PageRoutes.SESSIONS_LIBRARY ? 'active' : ''} to={PageRoutes.SESSIONS_LIBRARY}>
              <Start />
              <p>{t('menu_session_library')}</p>
            </Link>
            <Link className={location.pathname === PageRoutes.PROGRAMS_LIBRARY ? 'active' : ''} to={PageRoutes.PROGRAMS_LIBRARY}>
              <Programs />
              <p>{t('programs')}</p>
            </Link>
            <Link className={location.pathname === PageRoutes.TO_DOS_LIBRARY ? 'active' : ''} to={PageRoutes.TO_DOS_LIBRARY}>
              <CheckListIcon />
              <p>{t('profile_to_dos')}</p>
            </Link>
          </div>
        </MobileFooter>
      )}
    </>
  );
}
