import { effect } from '@preact/signals-react';
import { throttle } from 'lodash';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/pt';
import { lazy, Suspense, useEffect, useState } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ThemeProvider } from 'styled-components';
import { useLazyGetUserProfileQuery } from './api/apiSlice';
import CookieBar from './components/CookieBar';
import RewardToast from './components/Toast/RewardToast';
import { withAdminAuth } from './hocs/withAdminAuth';
import { withAuth } from './hocs/withAuth';
import { useAnalytics } from './hooks/useAnalytics';
import { setCookies } from './lib/cookies';
import ToDos from './pages/ToDos';
import { getWithExpiry, setWithExpiry } from './services/chunkFailedStorage';
import AppContext from './services/context';
import { history } from './services/history';
import i18n from './services/i18n';
import { isMobile } from './signals/global.signals';
import './styles/antd-components';
import GlobalStyle from './styles/globals';
import RebootStyle from './styles/reboot';
import variables from './styles/variables';

const Welcome = lazy(() => import('./pages/Welcome'));
const WelcomeNewProgramPage = lazy(() => import('./features/welcome/WelcomeNewProgramPage'));
const Home = lazy(() => import('./pages/Home'));
const Login = lazy(() => import('./pages/Login'));
const SignUp = lazy(() => import('./pages/SignUp'));
const RegistrationPage = lazy(() => import('./features/registration/RegistrationPage'));
const Articles = lazy(() => import('./pages/Article'));
const Goals = lazy(() => import('./pages/goals'));
const NewGoal = lazy(() => import('./pages/new-goal'));
const Profile = lazy(() => import('./pages/UserProfile'));
const LumiaChat = lazy(() => import('./pages/LumiaChat'));
const NewProgramPage = lazy(() => import('./features/program/NewProgramPage'));
const ProgramsLibraryPage = lazy(() => import('./features/program/ProgramsLibraryPage'));
const ProgramPage = lazy(() => import('./pages/Program'));
const ChallengePage = lazy(() => import('./features/challenge/ChallengePage'));
const SessionsLibraryPage = lazy(() => import('./features/session/sessionsLibraryPage'));
const SessionPage = lazy(() => import('./features/session/sessionPage'));
const AISessionPage = lazy(() => import('./pages/AISession'));
const AISessionHistoryPage = lazy(() => import('./pages/AISessionHistory'));
const InsightsPage = lazy(() => import('./features/insights/InsightsPage'));
const AddInsightPage = lazy(() => import('./features/insights/AddInsightPage'));
const InsightDetailsPage = lazy(() => import('./features/insights/InsightDetailsPage'));

const AdminDashboard = lazy(() => import('./pages/admin/dashboard'));
const AdminLogin = lazy(() => import('./pages/admin/login'));
const AdminUsers = lazy(() => import('./pages/admin/users'));

const CreatePassword = lazy(() => import('./pages/CreatePassword'));
const EditPassword = lazy(() => import('./pages/EditPassword'));
const Recover = lazy(() => import('./pages/recover'));
const ConfirmationPage = lazy(() => import('./features/partner/confirmation/ConfirmationPage'));
const Privacy = lazy(() => import('./pages/privacy'));

const ProgramMethodologyPage = lazy(() => import('./features/blog/ProgramMethodologyPage'));
const LuminousMethodologyPage = lazy(() => import('./features/blog/LuminousMethodologyPage'));
const CertificatePage = lazy(() => import('./features/certificate/CertificatePage'));

const ArticlesWrapped = withAuth(Articles);
const GoalsWrapped = withAuth(Goals);
const WelcomeWrapped = withAuth(Welcome);
const WelcomeNewProgramPageWrapped = withAuth(WelcomeNewProgramPage);
const HomeWrapped = withAuth(Home);
const NewGoalWrapped = withAuth(NewGoal);
const NewProgramPageWrapped = withAuth(NewProgramPage);
const ProfileWrapped = withAuth(Profile);
const LumiaChatWrapped = withAuth(LumiaChat);
const ProgramsLibraryPageWrapped = withAuth(ProgramsLibraryPage);
const ProgramPageWrapped = withAuth(ProgramPage);
const ChallengePageWrapped = withAuth(ChallengePage);
const SessionsLibraryPageWrapped = withAuth(SessionsLibraryPage);
const SessionPageWrapped = withAuth(SessionPage);
const AISessionPageWrapped = withAuth(AISessionPage);
const AISessionHistoryPageWrapped = withAuth(AISessionHistoryPage);
const InsightsPageWrapped = withAuth(InsightsPage);
const AddInsightPageWrapped = withAuth(AddInsightPage);
const InsightDetailsPageWrapped = withAuth(InsightDetailsPage);
const ToDoPageWrapped = withAuth(ToDos)
const AdminDashboardWrapped = withAdminAuth(AdminDashboard);
const AdminUsersWrapped = withAdminAuth(AdminUsers);
function changeDomain(currentURL, newDomain) {
  const url = new URL(currentURL);
  url.hostname = newDomain;
  url.port = '';
  // Adjust the protocol if necessary, e.g., url.protocol = 'https:'
  url.protocol = 'https:';
  return url.toString();
}

effect(() => {
  // Resize event listener to update isMobile
  const updateIsMobile = throttle(() => {
    isMobile.value = window.innerWidth < 767;
  }, 100);

  // Attach the resize event listener
  window.addEventListener('resize', updateIsMobile);

  // Clean up the event listener when effect is disposed
  return () => {
    window.removeEventListener('resize', updateIsMobile);
  };
});
export function App() {
  const [userRole, setUserRole] = useState('');

  const [getUserProfile] = useLazyGetUserProfileQuery({ skip: userRole !== '' });
  const [translations, setTranslations] = useState(i18n.load());
  const [, setLang] = useState('EN');
  const [isOnboardingCompleted, setIsOnboardingCompleted] = useState(false);

  useAnalytics();

  useEffect(() => {
    const currentURL = window.location.href;
    if (process.env.NODE_ENV === 'production' && !currentURL.includes('dev') && !currentURL.includes('luminous.coach')) {
      // Logic to perform the domain change
      // This will cause a page navigation/reload
      const newURL = changeDomain(currentURL, 'app.luminous.coach');
      window.location.href = newURL;
    }
  }, []);

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (/Loading chunk [\d]+ failed/.test(e.message)) {
        if (!getWithExpiry()) {
          setWithExpiry('true', 10000);

          window.location.reload();
        }
      }
    });
  }, []);

  useEffect(() => {
    const changeLanguage = (lang) => {
      switch (lang) {
        case 'en':
        case 'fr':
        case 'pt':
          setCookies('lang', lang, 30);
          break;
        default:
          setCookies('lang', 'en', 30);
          break;
      }
    };
    const manageUserData = async () => {
      await getUserProfile().then((data) => {
        if (data.data != null) {
          changeLanguage(data.data.language);
          moment.locale(data.data.language);

          const translations = i18n.load();
          setTranslations(translations);
          setLang(data.data.language);
          setUserRole(data.data.role);
          setIsOnboardingCompleted(Boolean(data.data?.userSettings?.isOnboardingComplete));
        } else {
          const lang = window.navigator.language.slice(0, 2) ?? 'en';
          changeLanguage(lang);
          moment.locale(lang);

          const translations = i18n.load();
          setTranslations(translations);
          setLang(lang);
        }
      });
    };
    manageUserData();
    // TODO: Disabling this for now. Need to know the purpose of this to create support on backend v2
    // Heartbeat.send();
    // this.interval = requestInterval(() => {
    //   Heartbeat.send();
    // }, 60 * 1000);
  }, [getUserProfile]);

  // TODO: Find out what is interval
  // useEffect(() => {
  //   return () => {
  //     if (this.interval) {
  //       window.cancelAnimationFrame(this.interval);
  //     }
  //   };
  // }, []);

  return (
    <ThemeProvider theme={[variables].reduce((p, c) => Object.assign(p, c))}>
      <RebootStyle />
      <GlobalStyle theme={variables} />
      <Router history={history}>
        <AppContext.Provider value={translations}>
          <Route
            render={({ location }) => {
              return (
                <TransitionGroup>
                  <CSSTransition key={location.key} classNames="page-transition" timeout={500}>
                    <Suspense fallback={<LoadingBar height={3} progress={80} color="#fc6453" />}>
                      <Switch location={location}>
                        <Route path="/" exact render={(props) => <Login {...props} />} />
                        <Route path="/articles/:id" exact render={(props) => <ArticlesWrapped {...props} />} />
                        <Route path="/goals/:id" exact render={(props) => <GoalsWrapped {...props} />} />

                        <Route
                          path="/welcome"
                          exact
                          render={(props) => (isOnboardingCompleted ? <Redirect to="/home" /> : <WelcomeWrapped {...props} />)}
                        />
                        <Route path="/welcome/new-program/:id" exact render={(props) => <WelcomeNewProgramPageWrapped {...props} />} />
                        <Route path="/home" exact render={(props) => <HomeWrapped {...props} />} />
                        <Route path="/new-goal" exact render={(props) => <NewGoalWrapped {...props} />} />
                        <Route path="/new-program/:id" exact render={(props) => <NewProgramPageWrapped {...props} />} />
                        <Route path="/profile" exact render={(props) => <ProfileWrapped {...props} />} />
                        <Route path="/lumia-chat" exact render={(props) => <LumiaChatWrapped {...props} />} />
                        <Route path="/programs-library" exact render={(props) => <ProgramsLibraryPageWrapped {...props} />} />
                        {/* <Route path="/new-program/:id" exact render={props => <ProgramPageWrapped {...props} />} /> */}
                        <Route path="/programs/:id" exact render={(props) => <ProgramPageWrapped {...props} />} />
                        <Route path="/programs/:id/challenge/:actionId" exact render={(props) => <ChallengePageWrapped {...props} />} />
                        <Route path="/sessions-library" exact render={(props) => <SessionsLibraryPageWrapped {...props} />} />
                        <Route path="/sessions/:id" exact render={(props) => <SessionPageWrapped {...props} />} />
                        <Route path="/ai-sessions/:id" exact render={(props) => <AISessionPageWrapped {...props} />} />
                        <Route path="/ai-session-histories/:id" exact render={(props) => <AISessionHistoryPageWrapped {...props} />} />
                        <Route
                          path="/ai-session-histories/:id/:sessionId"
                          exact
                          render={(props) => <AISessionHistoryPageWrapped {...props} />}
                        />
                        <Route path={"/to-dos-library"} exact render={(props) => <ToDoPageWrapped {...props} />} />
                        <Route path="/insights" exact render={(props) => <InsightsPageWrapped {...props} />} />
                        <Route path="/insights/add-an-insight" exact render={(props) => <AddInsightPageWrapped {...props} />} />
                        <Route path="/insights/insight-details" exact render={(props) => <InsightDetailsPageWrapped {...props} />} />

                        <Route path="/admin/login" exact render={(props) => <AdminLogin {...props} />} />
                        <Route path="/admin/dashboard" exact render={(props) => <AdminDashboardWrapped {...props} />} />
                        <Route path="/admin/users/:id" exact render={(props) => <AdminUsersWrapped {...props} />} />

                        <Route path="/users/password/create" exact render={(props) => <CreatePassword {...props} />} />
                        <Route path="/users/password/edit" exact render={(props) => <EditPassword {...props} />} />
                        <Route path="/recover" exact render={(props) => <Recover {...props} />} />
                        <Route path="/mentor/program/accept" exact render={(props) => <ConfirmationPage isProgram {...props} />} />
                        <Route path="/mentor/goal/accept" exact render={(props) => <ConfirmationPage {...props} />} />
                        <Route path="/privacy" exact render={(props) => <Privacy {...props} />} />
                        <Route path="/login" exact render={(props) => <Login {...props} />} />
                        <Route path="/registration" exact render={(props) => <RegistrationPage {...props} />} />
                        <Route path="/signup/:key" exact render={(props) => <SignUp {...props} />} />
                        <Route path="/signup" exact render={(props) => <SignUp {...props} />} />
                        <Route path="/blog/program-methodology" exact render={(props) => <ProgramMethodologyPage {...props} />} />
                        <Route path="/blog/luminous-methodology" exact render={(props) => <LuminousMethodologyPage {...props} />} />
                        <Route path="/certificates/:key" exact render={(props) => <CertificatePage {...props} />} />

                        {/* test url */}
                        <Route path="/test-welcome" exact render={(props) => <WelcomeWrapped {...props} />} />
                        <Route path="*">404 Not Found</Route>
                      </Switch>
                    </Suspense>
                  </CSSTransition>
                </TransitionGroup>
              );
            }}
          />
          <RewardToast getState={(state) => state.toastr} />
          <CookieBar />
        </AppContext.Provider>
      </Router>
    </ThemeProvider>
  );
}
