import types from './actions/types';
import initialState from './initialState';

export default (state = initialState.profile, action) => {
  switch (action.type) {
    case types.UPDATE_PROFILE:
    case types.SHOW_PROFILE:
      return action.payload;
    case types.UPLOAD_PICTURE_PROFILE: {
      return {
        data: {
          ...state.data,
          ...action.payload.data,
        },
      };
    }
    default:
      return state;
  }
};
