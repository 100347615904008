import styled from 'styled-components';
import { minWidthQuery } from '../../utils';

type Props = {
  columns: number;
  equal: boolean;
  gap: string;
};

export const Grid = styled.div<Partial<Props>>`
  ${props =>
    minWidthQuery('sm')(`
    display: grid;
    grid-template-columns: repeat(${props.columns || 3}, ${props.equal ? 'minmax(0, 1fr)' : '1fr'});
    grid-column-gap: ${props.gap || '20px'};
  `)};
`;
