import { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { history } from '../services';

declare const window: Window & { dataLayer: Record<string, unknown>[] };

export function useAnalytics() {
  const initGA = () => {
    const TRACKING_ID = process.env.REACT_APP_GTM;
    if (TRACKING_ID && window.dataLayer) {
      window.dataLayer.push({
        page_title: document.title,
        page_path: window.location.pathname + window.location.search,
      });

      history.listen(location => {
        const page = location.pathname + location.search;
        window.dataLayer.push({
          page_title: document.title,
          page_path: page,
        });
      });
    }
  };

  const initFacebookPixel = () => {
    ReactPixel.init('996158027489990');
    ReactPixel.pageView(); // For tracking page view
  };

  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === 'production' && process.env.REACT_APP_SITE_URL === 'https://dev.app.v2.becomeluminous.com') {
      initGA();
      initFacebookPixel();
    }
  }, []);
}
