const SVGMeditateStatus = (props) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.3244 14.0034L16.8861 14.8145L16.1909 12.9556V18.2668H8.4656V12.9556L7.77032 14.8145L5.33203 14.0034L6.00799 11.7775C6.16469 11.2646 6.46072 10.8052 6.86309 10.4506C7.26546 10.0959 7.75838 9.85997 8.28695 9.76895C11.4205 9.24267 10.1603 9.32958 13.7574 9.32958L16.3502 9.76412C16.8828 9.85224 17.3803 10.0874 17.7864 10.4432C18.1925 10.7989 18.4911 11.2611 18.6485 11.7775C19.392 14.2206 19.2665 13.8054 19.3244 14.0034Z"
      fill="#EA901B"
    />
    <path
      d="M19.3268 14.0034L16.8885 14.8145L16.1933 12.9556V17.7839H10.3993C10.1432 17.7839 9.89758 17.6822 9.71649 17.5011C9.53539 17.32 9.43365 17.0744 9.43365 16.8183V13.5543C9.43424 13.4403 9.40557 13.3281 9.35039 13.2283C9.2952 13.1286 9.21535 13.0447 9.11846 12.9846C9.02157 12.9245 8.91089 12.8903 8.79701 12.8853C8.68312 12.8802 8.56984 12.9044 8.46799 12.9556C8.01896 14.1579 8.1107 13.9213 7.98516 14.211C7.5204 14.0219 7.14298 13.6661 6.92685 13.2133C6.71071 12.7605 6.67144 12.2433 6.81672 11.763C6.94484 11.0816 7.28242 10.4571 7.78237 9.97657L8.2652 9.76895C11.4133 9.24267 10.1482 9.32958 13.7453 9.32958L16.3381 9.76412C16.8708 9.85224 17.3682 10.0874 17.7743 10.4432C18.1804 10.7989 18.479 11.2611 18.6364 11.7775C19.3944 14.2206 19.2689 13.8054 19.3268 14.0034Z"
      fill="#FF9307"
    />
    <path
      d="M13.7759 8.22266C13.7759 9.02415 13.8628 9.54078 13.4089 10.0526C13.2141 10.2713 12.9574 10.4258 12.6729 10.4957C12.3884 10.5656 12.0894 10.5476 11.8154 10.4441C11.5413 10.3405 11.3051 10.1563 11.1379 9.91571C10.9707 9.67515 10.8804 9.38953 10.8789 9.09658V8.22266H13.7759Z"
      fill="#F6CCAF"
    />
    <path
      d="M13.779 8.22266C13.779 9.02415 13.8659 9.54078 13.412 10.0526C13.2131 10.0685 13.013 10.0432 12.8243 9.97824C12.6357 9.91324 12.4625 9.80995 12.3156 9.67485C12.1687 9.53976 12.0513 9.37577 11.9708 9.19317C11.8903 9.01058 11.8483 8.81331 11.8477 8.61375V8.22266H13.779Z"
      fill="#FFDEC7"
    />
    <path
      d="M15.2276 3.7837C15.2276 5.5412 15.4786 6.96072 14.0832 8.01811C13.6539 8.34436 13.142 8.54429 12.6052 8.5954C12.0684 8.64652 11.5279 8.54679 11.0447 8.30744C10.5615 8.06809 10.1547 7.69862 9.87005 7.24062C9.58541 6.78262 9.43425 6.25426 9.43359 5.71502V3.7837C9.43359 3.01537 9.73881 2.27851 10.2821 1.73522C10.8254 1.19194 11.5622 0.886719 12.3306 0.886719C13.0989 0.886719 13.8358 1.19194 14.379 1.73522C14.9223 2.27851 15.2276 3.01537 15.2276 3.7837V3.7837Z"
      fill="#F6CCAF"
    />
    <path
      d="M15.2248 3.7855C15.2248 5.543 15.4759 6.96252 14.0805 8.01992C13.6499 8.14148 13.1969 8.16163 12.7572 8.07881C12.3175 7.99599 11.903 7.81244 11.5461 7.54255C11.1892 7.27267 10.8997 6.92378 10.7002 6.52325C10.5008 6.12272 10.3968 5.68144 10.3965 5.23399C10.3965 3.47649 10.1454 2.05697 11.5408 0.999577C11.9714 0.878021 12.4243 0.857864 12.864 0.940686C13.3038 1.02351 13.7183 1.20706 14.0752 1.47694C14.4321 1.74683 14.7216 2.09571 14.921 2.49624C15.1205 2.89677 15.2245 3.33806 15.2248 3.7855V3.7855Z"
      fill="#FFDEC7"
    />
    <path
      d="M15.2276 3.7837V4.26653C14.0832 4.26653 12.9486 4.40172 11.8477 3.30087C11.3118 4.39206 10.2689 4.26653 9.43359 4.26653V3.7837C9.43359 3.01537 9.73881 2.27851 10.2821 1.73522C10.8254 1.19194 11.5622 0.886719 12.3306 0.886719C13.0989 0.886719 13.8358 1.19194 14.379 1.73522C14.9223 2.27851 15.2276 3.01537 15.2276 3.7837V3.7837Z"
      fill="#A87E6B"
    />
    <path
      d="M15.2267 3.78321V4.26604C14.0824 4.26604 12.9478 4.40123 11.8469 3.30038C11.7137 3.57603 11.5091 3.81097 11.2544 3.98079C10.9997 4.15061 10.7041 4.24911 10.3984 4.26604V3.78321C10.3991 3.0991 10.6419 2.43731 11.0838 1.91503C11.5256 1.39276 12.138 1.04371 12.8126 0.929688C13.4871 1.04371 14.0996 1.39276 14.5414 1.91503C14.9833 2.43731 15.226 3.0991 15.2267 3.78321V3.78321Z"
      fill="#BE927C"
    />
    <path
      d="M16.1897 20.1992V23.0962H9.00523C8.74745 23.0916 8.50091 22.9898 8.31488 22.8113C8.12885 22.6328 8.01704 22.3907 8.00179 22.1333C7.98655 21.8759 8.06899 21.6223 8.23264 21.423C8.39629 21.2238 8.62909 21.0937 8.88453 21.0587C11.5111 20.682 3.95484 21.6477 16.1897 20.1992Z"
      fill="#F6CCAF"
    />
    <path
      d="M16.1933 20.1992V22.1305H9.97446C9.83502 22.1308 9.69701 22.1025 9.56888 22.0475C9.44076 21.9924 9.32523 21.9118 9.22938 21.8105C9.13354 21.7093 9.05939 21.5895 9.0115 21.4585C8.9636 21.3276 8.94297 21.1882 8.95086 21.049L16.1933 20.1992Z"
      fill="#FFDEC7"
    />
    <path
      d="M18.9435 23.0982H16.1914V20.2012C18.8566 19.8971 18.9628 19.8198 19.4795 19.9936C19.8481 20.1077 20.1625 20.3521 20.3641 20.681C20.5657 21.01 20.6407 21.4011 20.5751 21.7813C20.5094 22.1615 20.3077 22.5048 20.0074 22.7472C19.7072 22.9895 19.329 23.1143 18.9435 23.0982V23.0982Z"
      fill="#2C5F6E"
    />
    <path
      d="M9.00723 23.0962C5.62742 23.0962 5.1977 23.2459 4.58933 22.6134C4.40266 22.4238 4.26661 22.1903 4.19367 21.9344C4.12073 21.6786 4.11323 21.4084 4.17186 21.1489C4.2305 20.8894 4.35339 20.6487 4.52926 20.449C4.70513 20.2494 4.92833 20.0971 5.17839 20.0061C5.66122 19.8323 5.32324 19.8516 12.3243 20.6628L8.88652 21.0587C8.63109 21.0937 8.39828 21.2239 8.23463 21.4231C8.07098 21.6223 7.98854 21.876 8.00379 22.1333C8.01903 22.3907 8.13084 22.6329 8.31687 22.8114C8.5029 22.9899 8.74944 23.0916 9.00723 23.0962V23.0962Z"
      fill="#2C5F6E"
    />
    <path
      d="M12.3278 20.6822L8.89002 21.0781C8.64441 21.107 8.41746 21.2235 8.25075 21.4062C8.08403 21.5889 7.98873 21.8255 7.9823 22.0727C7.9823 22.1548 8.14164 22.1307 6.67866 22.1307C6.42381 22.1306 6.1727 22.0693 5.94641 21.9521C5.72012 21.8349 5.52524 21.6651 5.37814 21.457C5.23105 21.2489 5.13601 21.0085 5.10103 20.7561C5.06604 20.5036 5.09211 20.2465 5.17706 20.0062C5.70335 19.8227 5.47159 19.871 12.3278 20.6822Z"
      fill="#357588"
    />
    <path
      d="M19.5712 20.5392C19.5712 20.9618 19.4033 21.3671 19.1045 21.6659C18.8057 21.9647 18.4005 22.1326 17.9779 22.1326H16.1914V20.2012C18.8566 19.8971 18.9628 19.8198 19.4795 19.9936C19.5396 20.1693 19.5706 20.3536 19.5712 20.5392V20.5392Z"
      fill="#357588"
    />
    <path
      d="M19.0883 19.9208C18.8903 19.8918 19.3007 19.858 12.3287 20.6644C12.2804 20.6644 12.3963 20.6644 5.89739 19.9208C5.51112 19.887 4.93173 20.2974 8.46604 18.2695H16.1913L19.0883 19.9208Z"
      fill="#2C5F6E"
    />
    <path
      d="M18.4802 19.5828C13.1594 20.2009 13.338 20.2009 13.2946 20.2009L8.94909 19.6215C8.84869 19.6087 8.75485 19.5646 8.68083 19.4956C8.60682 19.4265 8.55637 19.336 8.53662 19.2367C8.51687 19.1374 8.52882 19.0345 8.57078 18.9424C8.61274 18.8502 8.68259 18.7736 8.77045 18.7234L9.55263 18.2695H16.1915L18.4802 19.5828Z"
      fill="#357588"
    />
    <path
      d="M7.77091 14.818C7.05632 16.7494 6.99838 17.0487 6.57832 17.546C4.12072 20.5154 4.22211 20.443 3.8793 20.5782C3.62534 20.6825 3.34349 20.6975 3.07991 20.6207C2.81632 20.5439 2.58663 20.3798 2.42844 20.1554C2.27025 19.9311 2.19293 19.6596 2.20915 19.3855C2.22537 19.1115 2.33417 18.851 2.51772 18.6469C5.14914 15.4892 4.56492 16.5224 5.33262 14.0117C6.4383 14.369 5.8927 14.1904 7.77091 14.818V14.818Z"
      fill="#F6CCAF"
    />
    <path
      d="M7.77085 14.8174C7.05626 16.7487 6.99832 17.0481 6.57826 17.5454C4.12066 20.5148 4.22205 20.4424 3.87924 20.5776C3.70686 20.4953 3.55591 20.3741 3.43822 20.2237C3.32054 20.0732 3.23932 19.8975 3.20096 19.7104C3.1626 19.5232 3.16813 19.3298 3.21712 19.1451C3.26612 18.9605 3.35723 18.7897 3.48332 18.6462C6.13406 15.4692 5.55466 16.4349 6.21131 14.3008L7.77085 14.8174Z"
      fill="#FFDEC7"
    />
    <path
      d="M21.2612 20.6814C20.5369 20.6814 20.5369 20.4593 18.0793 17.543C17.6544 17.0602 17.5965 16.7319 16.8867 14.8151L19.325 14.0039C20.0879 16.505 19.4892 15.4524 22.1399 18.6391C22.2975 18.8126 22.4018 19.0278 22.4403 19.259C22.4789 19.4902 22.4502 19.7276 22.3576 19.9429C22.2649 20.1583 22.1123 20.3424 21.9179 20.4733C21.7235 20.6043 21.4955 20.6765 21.2612 20.6814Z"
      fill="#F6CCAF"
    />
    <path
      d="M21.7433 20.5752C21.5695 20.5057 21.4165 20.3926 21.2991 20.2469C18.2959 16.6498 18.9863 17.7845 17.6875 14.5495L19.3243 14.0039C20.0872 16.505 19.4885 15.4524 22.1392 18.6391C22.2653 18.783 22.3564 18.9541 22.4054 19.1391C22.4544 19.3241 22.4599 19.5179 22.4216 19.7054C22.3832 19.8928 22.302 20.0689 22.1844 20.2198C22.0667 20.3707 21.9157 20.4923 21.7433 20.5752V20.5752Z"
      fill="#FFDEC7"
    />
  </svg>
);
export default SVGMeditateStatus;
