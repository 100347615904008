
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../services/apiClient';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const mentionQuestions = createAsyncThunk('session/mention', async ({activeSessionId, questionsId}) => {
  const response = await apiClient.post(`/sessions/mention/${activeSessionId}`, {questionsId});
  return response.data;
});

const mentionQuestionsSlice = createSlice({
    name: 'mentionQuestionsSlice',
    initialState,
    reducers: {},
    extraReducers: {

    [mentionQuestions.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [mentionQuestions.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default mentionQuestionsSlice.reducer;
