import { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { getAuthenticatedUser } from '../../utils';

export const withAdminAuth = Component => {
  class WithAdminAuth extends PureComponent {
    render() {
      const user = getAuthenticatedUser();

      if (user && user.isAuthenticated && user.isAdmin) {
        return <Component {...this.props} user={user} />;
      }

      return <Redirect to="/admin/login" />;
    }
  }

  return WithAdminAuth;
};
