import styled from 'styled-components';

export const ToDosLayoutContainer = styled.div`
  max-width: 1800px;
  margin: 0 auto;

  margin-top: clamp(64px, 100px, 128px);

  @media screen and (min-width: 834px) {
    padding: 32px 6.5rem 40px;
  }
  @media screen and (max-width: 834px) {
    margin-top: clamp(64px, 80px, 128px);

    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 80px;
  }

  // width: clamp( 540px, 80% , 744px);
  // margin: 0;
  // margin-right: clamp(1rem, 10%, 6.5rem);
  // margin-left: clamp(1rem, 10%, 6.5rem);
  // @media screen and (max-width: 834px){
  // margin-right: clamp(1rem, 5%, 2.5rem);
  // margin-left: clamp(1rem, 5%, 2.5rem);
  // }
`;
export const ToDosHeaderContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 2fr 1fr 0fr;

  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  button {
    text-wrap: nowrap;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 10px 20px, rgba(0, 0, 0, 0.04) 0px 2px 6px, rgba(0, 0, 0, 0.04) 0px 0px 1px;
    margin-right: 16px;

    padding: 6px 14px;
    min-width: unset;
    place-self: end;
    width: fit-content;
    font-size: 14px;
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: 2fr 0fr 0fr;

    button {
      min-width: 36px;
      div {
        padding-right: 0;
      }
    }
  }
`;
export const ToDosGoalContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  @media screen and (max-width: 834px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  @media screen and (max-width: 767pxpx) {
    grid-template-columns: 1fr;
  }
  gap: 16px;
  &:has(.goal-container:hover) .goal-container:not(:hover) {
    opacity: 0.6;
    scale: 0.98;
  }
  &:has .loading {
    .goal-container {
      min-height: 160px;
      min-width: 240px;
    }
  }
`;
export const ToDosActionContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 0fr;
  margin: 32px 0;
  .ant-select{
    place-self: end;
  }
  button {
    text-wrap: nowrap;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 10px 20px, rgba(0, 0, 0, 0.04) 0px 2px 6px, rgba(0, 0, 0, 0.04) 0px 0px 1px;
    margin-right: 16px;

    padding: 6px 14px;
    min-width: unset;
    place-self: end;
    width: fit-content;
    font-size: 14px;
  }
`;
export const ToDosActionHeaderContainer = styled(ToDosHeaderContainer)`

  `;
export const GoalActionCreateFormContainer = styled.div`
  display: grid;
`;
