import { Select as SelectAntd } from 'antd';
import { Field } from 'formik';
import styled from 'styled-components';
import { ReactComponent as UserCheckIcon } from '../../images/user-check.svg';
import media, { mediaMax } from '../../styles/mixins/media';

export const FormContainer = styled.div`
  border-radius: ${(props) => props.theme.utils.rem(6)};
  padding: ${(props) => props.theme.layout.small};
  ${(props) => (props.noPaddingY ? `padding-top: 0; padding-bottom: 0;` : '')}
  background-color: ${(props) => props.theme.colors.white};
  width: 100%;
  ${(props) => props.relative && 'position:relative;'}

  ${(props) =>
    props.secondary &&
    `
    background-color: transparent;
    padding: ${props.theme.layout.medium} 0;
    ${props.noPaddingY ? `padding-top: 0; padding-bottom: 0;` : ''}
  `};

  header {
    margin-bottom: ${(props) => props.theme.layout.small};
  }

  ${media.sm`
    margin: ${(props) => (props.centered ? '0 auto' : 0)};
    ${(props) => props.centered && `justify-content: center;`}
    width: ${(props) => props.theme.utils.rem(400)};

    ${(props) =>
      props.secondary &&
      `
      width: 100%;
    `};
  `};
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

export const TextField = styled(Field)`
  border: none;
  padding: 0.7rem 12px;
  width: 100%;
  font-size: ${(props) => props.theme.utils.rem(13)};
  line-height: ${(props) => props.theme.utils.rem(29)};
  letter-spacing: 0;
  background-color: ${(props) => props.theme.colors.pampas};
  border-radius: ${(props) => props.theme.utils.rem(6)};
  height: ${(props) => props.theme.utils.rem(46)};
  color: #675f72;
  margin-top: 16px;

  ${mediaMax.sm`
    margin-top: 8px;
  `}

  &::-webkit-input-placeholder {
    opacity: 0.3;
    color: ${(props) => props.placeholderColor};
  }
  &::-moz-placeholder {
    opacity: 0.3;
    color: ${(props) => props.placeholderColor};
  }
  &:-ms-input-placeholder {
    opacity: 0.3;
    color: ${(props) => props.placeholderColor};
  }
  &:-moz-placeholder {
    opacity: 0.3;
    color: ${(props) => props.placeholderColor};
  }
  &:-webkit-autofill {
    border: none;
    -webkit-text-fill-color: ${(props) => props.theme.colors.portGore};
    animation-name: none;
  }

  ${(props) =>
    props.secondary &&
    `
    background-color: ${props.theme.colors.white};

    &::-webkit-input-placeholder {
      opacity: 1;
    }
    &::-moz-placeholder {
      opacity: 1;
    }
    &:-ms-input-placeholder {
      opacity: 1;
    }
    &:-moz-placeholder {
      opacity: 1;
    }
  `};

  ${(props) =>
    props.styles === 'solid' &&
    `
    background: #FCFCFD;
    border: 1px solid #CFD0D7;

    &:hover {
      border: 1px solid #FC6453;
    }
  `}
`;

export const TextAreaField = styled(TextField)`
  height: ${(props) => props.theme.utils.rem(172)};

  position: relative;
`;

export const Select = styled(SelectAntd)`
  width: 100%;
  margin-top: 16px;

  ${mediaMax.sm`
    margin-top: 8px;
  `}

  .ant-select-selection {
    border: 0;
    background: #ffffff;
    border-radius: 4px;
    padding: 8px 0;
    height: 46px;
  }

  .ant-select-selection-selected-value {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
    align-items: center;
    color: #675f72;
  }

  ${(props) =>
    props.styles === 'solid' &&
    `
    .ant-select-selection {
      background: #FCFCFD;
      border: 1px solid #CFD0D7;
    }
  `}
`;

export const ColumnWrapper = styled.div`
  display: flex;
  margin-left: -8px;
  margin-right: -8px;

  ${mediaMax.sm`
    margin-left: -4px;
    margin-right: -4px;
  `}
`;

export const ColumnSplit = styled.div`
  flex: 1;
  padding: 0 8px;

  ${mediaMax.sm`
    padding: 0 4px;
  `}
`;

export const UserCheck = styled(UserCheckIcon)`
  display: block;
  justify-content: center;
  margin-bottom: 16px;
  width: 100%;
`;

export const SuccessPanel = styled.div`
  background: #ffffff;
  border-radius: 4px;
  color: #ff7864;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  display: flex;
  flex-direction: column;
  letter-spacing: -0.02em;
  padding: 28px;
  justify-content: center;
  text-align: center;

  .text {
    margin: auto;
    width: 100%;

    ${media.md`
      width: 328px;
    `}
  }
`;

export const FieldError = styled.div`
  font-size: 13px;
  line-height: 13px;
  color: #fc6453;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 8px;
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
`;

export const SuccessTitle = styled.div`
  font-weight: normal;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #211e42;
  margin-bottom: 8px;
`;

export const SuccessSubTitle = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #675f72;

  & > .orange {
    color: #fc6453;
    font-weight: 600;
  }
`;

export const LinksWrapper = styled.div`
  margin-top: ${(props) => props.mt || '32px'};
  text-align: center;
  font-size: 13px;
  line-height: 105%;
  letter-spacing: -0.01em;
  color: #675f72;
  font-style: normal;
`;

export const LinkText = styled.div`
  font-weight: 600;
  ${(props) => props.inline && 'display: inline;'}
  margin-bottom: 8px;
  cursor: pointer;
`;
