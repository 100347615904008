import { Popover } from 'antd';
import { Form, Formik } from 'formik';
import { Delete02Icon } from 'hugeicons-react';
import _ from 'lodash';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateUserActionMutation, useCreateUserGoalActionsMutation, useDeleteUserGoalActionsMutation, useGetUserProfileQuery, useUpdateGoalMutation, useUpdateUserActionMutation, useUpdateUserGoalActionsMutation } from '../../api/apiSlice';
import { ReactComponent as Menu } from '../../images/more-2.svg';

import SVGBucketStatus from './goal-icons/bucket';
import SVGCactusStatus from './goal-icons/cactus';
import SVGCakeStatus from './goal-icons/cake';
import SVGMeditateStatus from './goal-icons/meditate';
import SVGMontainStatus from './goal-icons/montain';
import {
  GoalCardContainer,
  GoalCardStatus,
  GoalCTAInfo,
  GoalDescContainer, GoalIconCtaContainer,
  GoalPopoverContainer
} from './goalCard.style';
import GoalDrawer from './goalDrawer';
import { GoalCreateDrawerContent } from './goalDrawer.style';
import GoalDrawerFormContent from './goalDrawerFormContent';

const transformInitialValues = (data) => {
  return {
    goal: data?.goal,
    actions: data?.actions.flat().map(action => action?.attributes)
  };
};

const GoalCard = ({ goal, hasMaxHeight, userGoalActionId, actions, onDelete,onUpdateGoal,highlightCommonGoal, ...props }) => {
  const { t } = useTranslation();

  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [updateGoal] = useUpdateGoalMutation();
  const [createUserGoalAction] = useCreateUserGoalActionsMutation();
  const [createUserAction] = useCreateUserActionMutation();
  const [updateUserAction] = useUpdateUserActionMutation();
  const [updateUserGoalAction] = useUpdateUserGoalActionsMutation();

  const [deleteGoalAction] = useDeleteUserGoalActionsMutation();
  const {data: user} = useGetUserProfileQuery();

  const goalFormValues = useMemo(() => {
    return transformInitialValues({
    goal: {
      id: goal.id ?? '',
      title: goal.title ?? '',
      description: goal.description ?? '',
      status: goal.status ?? '',
      motivationLevel: goal.motivationLevel ?? 4,
      url: goal.url ?? '',
      completionDate: goal.completionDate ?? null
    },
    actions: actions ??[]})
  },[actions, goal]);

  const currentStatusImage = useMemo(() => {
    switch (goal?.motivationLevel) {
      case  2:
        return <SVGCakeStatus />;
      case  4:
        return <SVGBucketStatus />;
      case  6:
        return <SVGMeditateStatus />;
      case  8:
        return <SVGCactusStatus />;
      case  10:
        return <SVGMontainStatus />;
      default:
        return <SVGMeditateStatus />;
    }
  }, [goal?.motivationLevel]);
  const currentGoalStatus = useMemo(() => {
    switch (goal?.status) {
      case 'active':
        return (
          <GoalCardStatus status={goal?.status}> {t('todos_status_goal_on_it')}</GoalCardStatus>
        );
      case 'completed':
        return (
          <GoalCardStatus status={goal?.status}>{t('todos_status_goal_completed')} </GoalCardStatus>
        );
      case 'stuck':
        return (
          <GoalCardStatus status={goal?.status}>{t('todos_status_goal_stuck')} </GoalCardStatus>
        );
      case 'not-started':
        return (
          <GoalCardStatus status={goal?.status}>{t('todos_status_goal_not_started')} </GoalCardStatus>
        );
      default:
        return;

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goal?.status]);
  const displayedDate = moment(goal?.completionDate).format('DD MMM');

  const handleDrawerVisibility = useCallback(() => {
    setDrawerVisible(!isDrawerVisible);
  }, [isDrawerVisible]);

  const handleSubmit = useCallback(async (values, initialValues) => {

    const newActions = values.actions.filter(action => !initialValues.actions.some(originalAction => _.isEqual(originalAction, action)));

    if (goal.id) {
      try {
        console.log('goal sent for update ', values.goal);
        await updateGoal({
          id: goal.id,
          ...values.goal
        }).then((data) => {
          console.log('data after update goal ', data);
          onUpdateGoal(data.data)}).finally(() => setDrawerVisible(false));

      } catch (error) {
        console.error('Error updating goal', error);
      }

    }
    console.log('values.actions ', values.actions);
    console.log('initialValues.actions ', initialValues.actions);
    console.log('newActions ', newActions);
    if(newActions.length > 0) {
      const goalActionsPromises = newActions.map(async (action) => {
        if(action.id == null){
          return createUserAction({
           contextType: 'goal',
           contextId: goal.id,
           title: action.title,
           note: action.note,
           status: action.status
         }).then(async (actionResponse) => {
           console.log('actionResponse ', actionResponse.data.data.id);
           await createUserGoalAction({
             actionId: actionResponse.data.data.id,
             goalId: goal.id,
             userId: user ? user.id : ''
           });
         }

         );
        } else {
          return updateUserAction({
            id: action.id,
            data: {
              contextType: action.contextType,
              contextId: action.contextId,
              title: action.title,
              note: action.note,
              status: action.status
            }
          })
        }



      });
      await Promise.allSettled(goalActionsPromises)

      setDrawerVisible(false)
    }
  }, [createUserAction, createUserGoalAction, goal.id, onUpdateGoal, updateGoal, updateUserAction, user]);


  const handleDelete = useCallback(async(e) => {
    e.stopPropagation();
    await deleteGoalAction({userGoalActionId: userGoalActionId ?? null, goalId: goal.id}).then(() => onDelete(goal.id));
    e.preventDefault();
  }, [deleteGoalAction, goal.id, onDelete, userGoalActionId]);
  if (goal == null) {
    return <></>;
  }

  return (
    <>
      <GoalCardContainer highlightCommonGoal={highlightCommonGoal} hasMaxHeight={hasMaxHeight} className={'goal-container'} onClick={(e) =>  {
        e.preventDefault();
        e.stopPropagation();
        handleDrawerVisibility();
}} {...props}>
        <GoalIconCtaContainer>
          {currentStatusImage}
            <Popover content={
              <GoalPopoverContainer onClick={(e) =>  handleDelete(e)}>
              <Delete02Icon/>
              </GoalPopoverContainer>
            } title={t('delete_goal')}>
            <Menu />
          </Popover>
        </GoalIconCtaContainer>
        <GoalDescContainer>{goal?.title}</GoalDescContainer>
        <GoalCTAInfo>
          {currentGoalStatus}
          {displayedDate}
        </GoalCTAInfo>
      </GoalCardContainer>
      <GoalDrawer
        isOpen={isDrawerVisible}
        onClose={() => setDrawerVisible(false)}
        children={
          <GoalCreateDrawerContent>
            <Formik
              initialValues={goalFormValues}
              onSubmit={(values) => handleSubmit(values, goalFormValues)}
              enableReinitialize={true} // This is crucial for your use case
          >
              {({ values,initialValues, isSubmitting, isValid , errors, submitForm}) => {

                return (<Form>
                  <GoalDrawerFormContent
                    goal={goal}
                  />
                </Form>)
              }}
            </Formik>
          </GoalCreateDrawerContent>
        }
      />
    </>
  );
};

export default GoalCard;
