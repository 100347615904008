import { getCookies } from '../lib/cookies';
import { ExtendedUserProfile } from '../types';

export const getAuthenticatedUser = (): Partial<ExtendedUserProfile> => {
  const userCookies = getCookies('user');
  const jwt = getCookies('jwt');
  const role = getCookies('role');

  if (!userCookies) {
    return {
      isAuthenticated: !!jwt,
      jwt,
      isAdmin: role === 'admin',
    };
  }

  const user = JSON.parse(userCookies.replace(/%22/g, '"').replace(/%2C/g, ','));
  return {
    isAuthenticated: !!jwt,
    jwt,
    isAdmin: role === 'admin',
    ...(user && user),
  };
};
