import axios from 'axios';

import Auth from './auth';
import { toast } from './toast';
import { getCookies } from '../lib/cookies';

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

apiClient.interceptors.request.use(config => {
  const jwtToken = getCookies('jwt');
  config.headers.Authorization = `Bearer ${jwtToken}`;
  return config;
})

apiClient.interceptors.response.use(function(response) {
  if (response.data.lumisAction) {
    toast(response.data.lumisAction);
  }

  return response;
});
