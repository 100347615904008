import { Drawer } from "antd";
import styled, { css } from "styled-components";


export const StyledGoalDrawer = styled(Drawer)`
  &:focus-visible{
  outline: none;
  }

  position: fixed;
  z-index: 1000;
  inset: 74px 0px 0px auto;
  border-radius: 12px 0 0 12px;
  box-shadow: -6px 1px 4px rgba(0, 0, 0, 0.03), -1px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
  background: white;
  padding:16px 56px;
  overflow-y: scroll;

@media screen and (max-width: 834px) {
  inset: 80px 0 0 0;
  border-radius : 24px;
  padding: 16px 24px;
}
.ant-drawer-mask{
  position: absolute;
  z-index: 0;
  inset:0;
  right: 100%;
  left: calc(-1 *(100vw - 100%));
  background-color: rgba(0,0,0,0.2);
  backdrop-filter: blur(2px);
-webkit-backdrop-filter:blur(2px);
}
  .ant-drawer-content-wrapper{
  width: 100% !important;
  .ant-drawer-content {
      background: transparent;
.ant-drawer-header-no-title{
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
}
      .ant-drawer-close {
          right: 10px;
          background: none;
          border:none;
          .anticon-close {
          cursor: pointer;
              border: 1px solid #C1C1C180;
              border-radius: 50%;
              padding: 3px;
              display: grid;
              width: 27px;
              place-items: center;
              height: 27px;
              svg {
                  height: 15px;
                  width: 15px;
                  fill: #675F72;

              }
          }
      }
  }


  `;
export const GoalDrawerBckd = styled.div`
  display: none;
  ${(props) => props.visible && css`
    display: unset;
    `}
  z-index: 1;
  content:'';
  position: absolute;
  cursor: pointer;
  inset: 0;
  background-color: rgba(0,0,0,0.2);
  backdrop-filter: blur(2px);
  `;
export const GoalCreateDrawerContent = styled.div`
  display: grid;
  .goal-field-title, .goal-field-note{
  height: 80px;
  margin-bottom: 24px;
  color: black;
  }
  .goal-field-note{
  height: 124px;

  margin-top: 0;
  }
  `;
