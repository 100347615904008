import styled, { css } from 'styled-components';
import { mediaMax } from '../../styles/mixins/media';
import { maxWidthQuery } from '../../utils';

type Props = {
  color: string;
  fz: string;
  fw: string;
  lh: string;
  lt: string;
  textDecoration: string;
  uppercase: boolean;
  textTrans: string;
  whiteSpace: string;
  wordBreak: string;
  mt: string;
  mb: string;
  mr: string;
  ml: string;
  minHeight: string;
  textShadow: string;
  padding: string;
  backgroundColor: string;
  cursor: string;
  secondaryFont: string;
  mlMd: string;
  fzSm: string;
  fwSm: string;
  lhSm: string;
  ltSm: string;
  mtSm: string;
  mbSm: string;
  mlSm: string;
  centerSm: string;
  paddingSm: string;
  primary: boolean;
  center: boolean;
  inline: boolean;
  bigFirstLetter: boolean;
  ellipsis: boolean;
  lineClamp: string;
  h: string;
};

export const Typography = styled.div<Partial<Props>>`
  color: ${(props) => props.color || '#675f72'};
  font-size: ${(props) => props.fz || '14px'};
  font-weight: ${(props) => props.fw || '400'};
  line-height: ${(props) => props.lh || '1'};
  letter-spacing: ${(props) => props.lt || '-0.02em'};
  text-decoration: ${(props) => props.textDecoration};
  text-transform: ${(props) => (props.uppercase && 'uppercase') || props.textTrans};
  white-space: ${(props) => props.whiteSpace};
  word-break: ${(props) => props.wordBreak};

  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-right: ${(props) => props.mr};
  margin-left: ${(props) => props.ml};
  min-height: ${(props) => props.minHeight};
  text-shadow: ${(props) => props.textShadow};
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backgroundColor};
  cursor: ${(props) => props.cursor};
  height: ${(props) => props.h};
  ${(props) =>
    props.secondaryFont &&
    `
    font-family: 'IBM Plex Serif', 'Work Sans', sans-serif;
  `}

  ${(props) =>
    maxWidthQuery('md')(`
    margin-left: ${props.mlMd};
  `)}

  ${(props) =>
    maxWidthQuery('sm')(`
    font-size: ${props.fzSm};
    font-weight: ${props.fwSm};
    line-height: ${props.lhSm};
    letter-spacing: ${props.ltSm};

    margin-top: ${props.mtSm};
    margin-bottom: ${props.mbSm};
    margin-left: ${props.mlSm};

    ${
      props.centerSm &&
      `
      text-align: center;
    `
    }
    padding: ${props.paddingSm};
  `)}

  ${(props) =>
    props.primary &&
    `
    color: #FC6453;
  `}

  ${(props) =>
    props.center &&
    `
    text-align: center;
  `}

  ${(props) =>
    props.inline &&
    `
    display: inline;
  `}

  ${(props) =>
    props.bigFirstLetter &&
    `
    &:first-letter {
      text-transform: uppercase;
    }
    `}
    ${(props) =>
    props.ellipsis &&
    css`
      overflow: hidden;
      ${props.lineClamp != null
        ? css`
            display: -webkit-box;
            -webkit-line-clamp: ${props.lineClamp};
            -webkit-box-orient: vertical;
          `
        : css`
            white-space: nowrap;
            text-overflow: ellipsis;
          `}
    `}
`;
