import { FlattenSimpleInterpolation } from 'styled-components';
import { Grid } from '../../stories/Grid';
import { Circle, ListItem, PlaceholderContent, Square, TextLine } from './styled';

type Props = {
  type: 'text' | 'square' | 'cards' | 'circles' | 'list';
  textSize?: string;
  textCenter?: boolean;
  squareSize?: string;
  columns?: number;
  customStyles?: FlattenSimpleInterpolation;
};

export function Placeholder({ type, textSize, textCenter, squareSize, columns, customStyles }: Props) {
  return (
    <PlaceholderContent>
      {type === 'text' && <TextLine textSize={textSize} textCenter={textCenter} customStyle={customStyles} />}

      {type === 'square' && <Square squareSize={squareSize} customStyle={customStyles} />}

      {type === 'cards' && (
        <Grid columns={columns}>
          {[...Array(columns || 1).keys()].map((_, i) => (
            <div key={i}>
              <Square customStyle={customStyles} />
              <TextLine customStyle={customStyles} />
              <TextLine customStyle={customStyles} />
            </div>
          ))}
        </Grid>
      )}

      {type === 'circles' && (
        <Grid columns={columns}>
          {[...Array(columns || 1).keys()].map((_, i) => (
            <Circle key={i} customStyle={customStyles} />
          ))}
        </Grid>
      )}

      {type === 'list' && (
        <div>
          {[...Array(columns || 1).keys()].map((_, i) => (
            <ListItem key={i} customStyle={customStyles} />
          ))}
        </div>
      )}
    </PlaceholderContent>
  );
}
