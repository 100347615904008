import isInteger from 'lodash/isInteger';
import styled, { css } from 'styled-components';
import { layoutSizes } from '../../constants';
import config from '../Grid/config';

type Props = {
  xs: number;
  xsOffset: number;
  sm: number;
  smOffset: number;
  md: number;
  mdOffset: number;
  lg: number;
  lgOffset: number;
  noPadding: boolean;
  reverse: boolean;
};

const offsetProps = layoutSizes.map(d => `${d}Offset`);

export const Col = styled.div<Partial<Props>>`
  box-sizing: border-box;
  flex: 0 0 auto;
  ${p =>
    !p.noPadding &&
    css`
      ${layoutSizes.map(
        t =>
          config(p).container[t] &&
          config(p).media[t]`
          padding-right: ${p => config(p).gutterWidth[t] / 2}px;
          padding-left: ${p => config(p).gutterWidth[t] / 2}px;
      `,
      )}
    `}


  ${p =>
    p.reverse &&
    `
    flex-direction: column-reverse;
  `}

  ${p =>
    Object.keys(p)
      .filter(k => ~layoutSizes.indexOf(k))
      .sort((k1, k2) => layoutSizes.indexOf(k1) - layoutSizes.indexOf(k2))
      .map(
        k =>
          config(p).media[k]`${
            isInteger(p[k])
              ? // Integer value
                `
        flex-basis: ${(100 / config(p).gridSize[k]) * p[k]}%;
        max-width: ${(100 / config(p).gridSize[k]) * p[k]}%;
        display: block;
      `
              : // Boolean
              p[k]
              ? // Auto-width
                `
          flex-grow: 1;
          flex-basis: 0;
          max-width: 100%;
          display: block;
        `
              : // Hide element
                'display: none;'
          }`,
      )}

  ${p =>
    Object.keys(p)
      .filter(k => ~offsetProps.indexOf(k))
      .map(
        k => config(p).media[k.replace(/Offset$/, '')]`
        margin-left: ${(100 / config(p).gridSize[k.replace(/Offset$/, '')]) * p[k]}%;
      `,
      )}
`;
