import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import data from '../../reducers/data';
import { apiClient } from '../../services/apiClient';
import { actionService } from './ProgramActionsList/actionService';

const initialState = {
  data: {},
  status: 'idle',
  error: null,
  userActionUpdating: '',
  userActionError: null,
};

const summaryTypes = ['session', 'article', 'challenge'];
const populateQuery = [
  'stepAnswers',
  'stepAnswers.step',
  'stepAnswers.stepSummaryAnswer',
  'stepAnswers.activityAnswers.activity',
  'program',
  'program.needs.content',
  'program.needs.skills',
  'program.needs.skills.content',
  'program.needs.skills.need',
  'program.steps.content',
  'program.steps.activities',
  'program.steps.activities.step',
  'program.steps.activities.article',
  'program.steps.activities.session',
  'program.steps.activities.article.content',
  'program.steps.activities.article.content.picture',
  'program.steps.activities.article.content.tips',
  'program.steps.activities.article.content.more',
  'program.steps.activities.session.content.picture',
  'program.steps.activities.session.content',
  'program.coaches.content',
  'program.coaches.picture',
  'program.coaches.video',
  'program.content',
  'program.content.picture',
];

const mapProgramSteps = (steps, userActions) =>
  steps.map((step) => {
    step.isSummaryRequired = step.actions.some((i) => summaryTypes.includes(i.action_type));
    step.actions = step.actions.map((action) => {
      const idField = actionService.isEventOrMeeting(action.action_type) ? 'program_event_id' : 'program_action_id';
      const userAction = userActions.find((uc) => uc[idField] === action.id);

      return {
        ...userAction,
        session_id: action.session_id,
        article_id: action.article_id,
        title: action.title,
        description: action.description,
        type: action.action_type,
        action_type: action.action_type,
        article: action.article,
        session: action.session,
        order: action.order,
        program_step_id: action.program_step_id,
        program_event_id: action.program_event_id,
      };
    });
    return step;
  });

// old program page
export const fetchUserProgram = createAsyncThunk('userProgram/fetchUserProgram', async (userProgramId) => {
  return await apiClient.get(`/user-program-answers/${userProgramId}`, {
    params: {
      populate: populateQuery,
    },
  });
  // const { id, program_id, picture_url, title, steps, needs, user_programs, stepsJournaling, articlesJournaling, surveyLink } = data.data;

  // return {
  //   program: {
  //     id,
  //     program_id,
  //     picture_url,
  //     title,
  //     need: needs[0],
  //     steps: mapProgramSteps(steps, user_programs[0].actions),
  //     surveyLink,
  //   },
  //   userProgram: { ...user_programs[0], preAssessment: user_programs[0].pre_assessment, postAssessment: user_programs[0].post_assessment },
  //   stepsJournaling,
  //   articlesJournaling,
  //   sessions: data.sessions,
  //   skills: needs[0].skills,
  // };
});
// here
export const updateUserProgram = createAsyncThunk('userProgram/updateUserProgram', async ({ id, body }) => {
  return await apiClient.put(`/user-program-answers/${id}`, { data: body }, { params: { populate: populateQuery } });
});

export const createUserProgram = createAsyncThunk('userProgram/createUserProgram', async ({ body }) => {
  return await apiClient.post(`/user-program-answers`, { data: body }, { params: { populate: populateQuery } });
});

export const startUserProgram = createAsyncThunk('userProgram/startUserProgram', async ({ userProgramId, body }) => {
  return await apiClient.post(`/user-program-answers/${userProgramId}/start`, { data: body }, { params: { populate: populateQuery } });
});

export const deleteUserProgram = createAsyncThunk('userProgram/deleteUserProgram', async (userProgramId) => {
  await apiClient.delete(`/user-program-answers/${userProgramId}`);
  return userProgramId;
});

export const updateUserAction = createAsyncThunk('userProgram/updateUserAction', async (data) => {
  const { data: userActions } = await apiClient.put(`/user-actions/${data.id}`, { action: data });

  return [{ userActions: userActions.data.find((action) => action.id === data.id) || data }, { lumisAction: userActions.lumisAction }];
});

//new
export const fetchStepSummary = createAsyncThunk('userProgram/fetchStepSummary', async ({ userStepAnswerId }) => {
  const { data } = await apiClient.get(`/user-step-answers/${userStepAnswerId}`);
  return data;
});
//NEED for new
export const addStepSummary = createAsyncThunk('userProgram/addStepSummary', async ({ userStepAnswerId, answers, isCompleted }) => {
  const { data } = await apiClient.put(
    `/user-step-answers/${userStepAnswerId}`,
    {
      data: {
        stepSummaryAnswer: answers,
        stepSummaryCompleted: isCompleted,
        completed: isCompleted,
        ...(isCompleted && { completedAt: new Date() }),
      },
    },
    {
      params: {
        populate: ['activityAnswers', 'activityAnswers.activity'],
      },
    },
  );

  return data;
});

// don't need, delete
// export const updateStepSummary = createAsyncThunk('userProgram/updateStepSummary', async ({ summaryId, answers, isCompleted }) => {
//   const { data: summary } = await apiClient.put(`/user-program-answers/step/summary/${summaryId}`, { answer: answers, isCompleted });

//   return summary;
// });

//new
export const fetchSettings = createAsyncThunk('userProgram/fetchSettings', async (userProgramId) => {
  const { data } = await apiClient.get(`/user-program-answers/${userProgramId}`, {
    params: {
      populate: populateQuery,
    },
  });

  return data;
});

const userProgramSlice = createSlice({
  name: 'userProgramSlice',
  initialState,
  reducers: {},
  extraReducers: {
    // old
    [fetchUserProgram.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchUserProgram.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload.data;
    },
    [fetchUserProgram.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    // old
    [createUserProgram.pending]: (state) => {
      state.status = 'loading';
    },
    [createUserProgram.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload.data;
    },
    [createUserProgram.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    [updateUserProgram.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload.data;
    },
    [startUserProgram.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      // state.data = action.payload;

      state.data = action.payload.data;
      // state.data.userProgram.preAssessment = action.payload.data.data.attributes.preAssessmentAnswer;
      // state.data.steps = action.payload.data.data.attributes.steps.data;
    },
    [deleteUserProgram.fulfilled]: (state, action) => {
      state.data = {};
      state.status = 'idle';
    },
    [updateUserAction.pending]: (state) => {
      state.userActionUpdating = 'loading';
    },
    [updateUserAction.fulfilled]: (state, action) => {
      const existAction = state.data.userProgram.actions.find((uc) => uc.id === action.payload[0].userActions.id);
      if (existAction) {
        existAction.status = action.payload[0].userActions.status;
        existAction.note = action.payload[0].userActions.note;
        existAction.date = action.payload[0].userActions.date;
        existAction.is_main = action.payload[0].userActions.is_main;
      }
      const existStepAction = selectProgramActionById(state, action.payload[0].userActions.id);
      if (existStepAction) {
        existStepAction.status = action.payload[0].userActions.status;
        existStepAction.note = action.payload[0].userActions.note;
        existStepAction.date = action.payload[0].userActions.date;
        existStepAction.is_main = action.payload[0].userActions.is_main;
      }
      if (action.payload[1].lumisAction) {
        state.data.userProgram.current_lumis = action.payload[1].lumisAction?.programLumisTotal;
      }
      state.userActionUpdating = '';
    },
    [updateUserAction.rejected]: (state, action) => {
      state.userActionUpdating = '';
      state.userActionError = action.payload;
    },

    [fetchStepSummary.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchStepSummary.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data.stepSummary = action.payload;
    },
    [fetchStepSummary.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    [addStepSummary.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data.stepSummary = action.payload;
    },

    [fetchSettings.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchSettings.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchSettings.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default userProgramSlice.reducer;

export const selectProgramActionById = (state, id) => {
  return state.data.program?.steps
    .map((step) => step.actions)
    .flat()
    .find((uc) => uc.id === id);
};
