import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../services/apiClient';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const startUserSession = createAsyncThunk('session/startUserSession', async ({ sessionId }) => {
  const response = await apiClient.post(
    `/user-session-answers/`,
    {
      data: {
        session: sessionId,
      }
    },
    {
      params: {
        populate: [
          'session', 'session.needs', 'session.needs.content',
          'session.content', 'session.content.picture',
          'session.questions', 'session.questions.content',
          'session.questions.content.groups',
          'session.questions.content.options',
          'answers', 'answers.answer'
        ]
      }
    }
  );
  return response.data;
});

export const updateUserSessionAnswer = createAsyncThunk(
  'session/saveAnswer',
  async ({ userSessionAnswerId, answers }) => {
    await apiClient.put(`/user-session-answers/${userSessionAnswerId}`, {
      data: { answers: answers.filter(ans => ans) }
    });
    // return response.data;
  },
);

export const updateUserActivityAnswer = createAsyncThunk(
  'session/saveAnswer',
  async ({ userActivityAnswerId, answers }) => {
    await apiClient.put(`/user-activity-answers/${userActivityAnswerId}`, {
      data: { answers: answers.filter(ans => ans) }
    });
    // return response.data;
  },
);

export const updateObjective = createAsyncThunk('session/updateObjective', async (objectiveId, objective) => {
  const response = await apiClient.put(`/objective/${objectiveId}/update`, objective);
  return response.data;
});

export const saveSession = createAsyncThunk('session/saveSession', async ({ userSessionAnswerId, lumart }) => {
  const response = await apiClient.post(`/user-session-answers/${userSessionAnswerId}/complete`, { lumart });
  return response.data;
});

export const saveActivity = createAsyncThunk('session/saveSession', async ({ userActivityAnswerId, lumart }) => {
  const response = await apiClient.post(`/user-activity-answers/${userActivityAnswerId}/complete`, { lumart });
  return response.data;
});

//get answers or new session, if session NOT completed, but user add some answer
export const activeSession = createAsyncThunk('session/activeSession', async ({ userProgramId, activityId, stepId }) => {
  const response = await apiClient.get(`/user-activity-answers/activity/${activityId}`, {
    params: {
      step: stepId,
      userProgram: userProgramId
    }
  });

  return response.data;
});

export const fetchObjectivesAndActions = createAsyncThunk('session/fetchObjectivesAndActions', async ({ activeSessionId }) => {
  const response = await apiClient.get(`/sessions/objectives-and-actions/${activeSessionId}`);
  return response.data;
});

//get answers if session completed, doesn't matter done or not done
export const completedSession = createAsyncThunk('session/completedSession', async ({ userProgramId, sessionId }) => {
  const response = await apiClient.get(`/user-program-answers/${userProgramId}/completed-session/${sessionId}`);
  return response.data;
});

const sessionSlice = createSlice({
  name: 'sessionsSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [startUserSession.pending]: state => {
      state.status = 'loading';
    },
    [startUserSession.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [startUserSession.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    [updateUserSessionAnswer.pending]: state => {
      state.status = 'loading';
    },
    [updateUserSessionAnswer.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [updateUserSessionAnswer.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    [saveSession.pending]: state => {
      state.status = 'loading';
    },
    [saveSession.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data.questions = action.payload.questions;
    },
    [saveSession.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    [completedSession.pending]: state => {
      state.status = 'loading';
    },
    [completedSession.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [completedSession.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    [activeSession.pending]: state => {
      state.status = 'loading';
    },
    [activeSession.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [activeSession.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default sessionSlice.reducer;
