import { css } from 'styled-components';

const features = {
  touch: typeof window !== "undefined"
    ? !document.documentElement.className.includes('no-touch')
    : true,
  notouch: typeof window !== "undefined"
    ? document.documentElement.className.includes('no-touch')
    : true,
};

export default Object.keys(features).reduce((acc, feature) => {
  if (features[feature]) {
    acc[feature] = (...args) => css`
      ${css(...args)}
    `;
  } else {
    acc[feature] = () => css``;
  }
  return acc;
}, {});
