import types from './actions/types';
import initialState from './initialState';

export default (state = initialState.goalSessions, action) => {
  switch (action.type) {
    case types.INDEX_GOAL_SESSIONS:
      return {
        data: action.payload.data,
        filtered: action.payload.data,
      };
    case types.FILTER_BY_NEED_GOAL_SESSIONS: {
      if (action.payload.id !== null) {
        const sessionsFiltered = state.data.filter(session =>
          session.needs.map(need => need.type).includes(action.payload.id)
        );
        return {
          data: state.data,
          filtered: sessionsFiltered,
        };
      }
      return {
        data: [],
        filtered: [],
      };
    }
    default:
      return state;
  }
};
