import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../services/apiClient';

const initialState = {
  data: null,
  status: 'idle',
  error: null,
  token: '',
  registrationState: 'idle',
  registrationError: null,
};

export const fetchCompanyName = createAsyncThunk('registration/fetchCompanyName', async key => {
  const response = await apiClient.get(`/user/registration/${key}`);
  return response.data;
});

export const register = createAsyncThunk('registration/register', async ({ key, data }) => {
  const registerData = {
    name: `${data.first_name} ${data.last_name}`,
    email: data.email,
    password: data.password,
    lang: data.lang,
  };

  const response = await apiClient.post(`/user/registration/${key}`, registerData);
  return response.data;
});

const registrationSlice = createSlice({
  name: 'registrationSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCompanyName.pending]: state => {
      state.status = 'loading';
    },
    [fetchCompanyName.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchCompanyName.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [register.pending]: state => {
      state.registrationState = 'loading';
    },
    [register.fulfilled]: (state, action) => {
      state.registrationState = 'succeeded';
      state.token = action.payload;
    },
    [register.rejected]: (state, action) => {
      state.registrationState = 'failed';
      state.registrationError = action.payload;
    },
  },
});

export default registrationSlice.reducer;
