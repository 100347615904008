import { ArticleContent, CoachContent, NeedContent, ProgramContent, SessionContent, SkillContent } from '../types';

export const isContentHasTitle = (
  content: ArticleContent | CoachContent | NeedContent | ProgramContent | SessionContent | SkillContent,
): ArticleContent | NeedContent | ProgramContent | SessionContent | null => {
  if ('title' in content) {
    return content;
  }
  return null;
};
