export default function generateMediaUrl(url) {
  if (!url) {
    return url;
  } else if (url?.startsWith("http://") || url?.startsWith("https://") || url?.startsWith("/static/images/")) {
    return url;
  } else {
    // Defaults by adding the backend api url
    return process.env.REACT_APP_API_DOMAIN + url;
  }
}

export const constructMediaUrl = (url: string): string => {
  if (url?.startsWith("http://") || url?.startsWith("https://") || url?.startsWith("/static/images/")) {
    return url;
  }
  // Defaults by adding the backend api url
  return process.env.REACT_APP_API_DOMAIN + url;
}
