import 'antd/lib/calendar/style/index.less';
import 'antd/lib/select/style/index.less';
import 'antd/lib/radio/style/index.less';
import 'antd/lib/modal/style/index.less';
import 'antd/lib/checkbox/style/index.less';
import 'antd/lib/input/style/index.less';
import 'antd/lib/collapse/style/index.less';
import 'antd/lib/popover/style/index.less';
import 'antd/lib/tabs/style/index.less';
import 'antd/lib/table/style/index.less';
import 'antd/lib/upload/style/index.less';
import 'antd/lib/descriptions/style/index.less';
import 'antd/lib/badge/style/index.less';
import 'antd/lib/message/style/index.less';
import 'antd/lib/button/style/index.less';
import 'antd/lib/date-picker/style/index.less';
import 'antd/lib/tag/style/index.less';
import 'antd/lib/carousel/style/index.less';
import 'antd/lib/grid/style/index.less';
