import styled from 'styled-components';
import { maxWidthQuery } from '../../utils';

export const ButtonWrapper = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  justify-content: center;
  padding: ${(props) => (props.padding ? props.padding : '12px 16px')};
  border: 0 none;
  border-radius: 4px;
  background-color: ${(props) => !props.background && '#fff'};
  font-size: 15px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '600')};
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #675f72;
  text-transform: ${(props) => (props.textTransform ? props.textTransform : 'uppercase')};
  transition: background 300ms ease-in-out, box-shadow 300ms ease-in-out;
  box-shadow: ${(props) => props.boxShadow};
  margin: ${(props) => props.margin};
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  mix-blend-mode: ${(props) => props.mixBlendMode};
  &:hover,
  &:active {
    background-color: ${(props) => !props.background && '#f2f2f2'};
    background: ${(props) => props.background && 'rgba(121, 102, 243, 1)'};
  }

  ${(props) =>
    props.hoverColorBorder &&
    `
    &:hover,
    &:active {
      background-color: #FEF9F9;
      box-shadow: 0 0 0px 1px #F9DFDB;
    }
  `}

  ${(props) =>
    props.centered &&
    `
    margin: 0 auto;
  `}

  ${(props) =>
    props.disabled &&
    `
    cursor: initial;
    opacity: .8;
  `}

  ${(props) =>
    props.size === 'small' &&
    `
    font-size: 12px;
    line-height: 14px;
  `}

  ${(props) =>
    props.size === 'middle' &&
    `
    font-size: 14px;
    line-height: 16px;
  `}

  ${(props) =>
    props.inline &&
    `
    display: inline-flex;
  `}

  ${(props) =>
    props.fullWidth &&
    `
    width: 100%;
  `}

  ${(props) =>
    props.width &&
    `
    width: ${props.width};
    max-width: 100%;
  `}

  ${(props) =>
    props.minWidth &&
    `
    min-width: ${props.minWidth};
  `}

  ${(props) =>
    props.styles === 'primary' &&
    `
    background-color: #fc6453;
    color: #fff;

    &:hover,
    &:active {
      background-color: #bf5d52;
    }
  `}

  ${(props) =>
    props.styles === 'border' &&
    `
    border: 1px solid #e0e0e0;
  `}

  ${(props) =>
    props.styles === 'prevBtn' &&
    `
    border: 1px solid #e0e0e0;
  `}

  ${(props) =>
    maxWidthQuery('sm')(`
      ${
        props.styles === 'prevBtn' &&
        `
      background: #fff;
      box-shadow: 0px 0px 5px rgb(0 0 0 / 5%);
      border: none;`
      },
    `)}


  ${(props) =>
    props.styles === 'transparent' &&
    `
    background-color: transparent;

    &:hover {
      background-color: #fff;
    }
  `}

  ${(props) =>
    props.styles === 'rounded' &&
    `
    border-radius: 40px;
    padding: 12px 24px;
  `}

  ${(props) =>
    props.marginRight &&
    `
    margin-right: ${props.marginRight};
  `}

  ${(props) =>
    props.marginBottom &&
    `
    margin-bottom: ${props.marginBottom};
  `}

  ${(props) =>
    maxWidthQuery('sm')(`
    margin: ${props.marginSm};
    ${
      props.mobileFullWidth &&
      `
      width: 100%;
      max-width: 100%;
    `
    }

    ${
      props.size === 'compact' &&
      `
      font-size: 10px;
      line-height: 12px;
      flex-direction: column;

      ${ButtonIcon} {
        padding: 0;
        margin-bottom: 4px;
      }
    `
    }
  `)}
`;

export const ButtonIcon = styled.div`
  display: flex;
  ${(props) => (props.post ? 'padding-left: 10px;' : 'padding-right: 13px;')}
`;
