const SVGMontainStatus = (props) => (
  <svg
    width={26}
    height={24}
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.10938 20.7682L6.93193 10.6611C7.41424 9.82385 8.3712 9.55543 9.13126 10.044L11.5412 11.5934V20.7682H1.10938Z"
      fill="#B66522"
    />
    <path
      d="M18.7415 5.63547L14.272 4.10547L7.76953 20.7677H24.895L18.7415 5.63547Z"
      fill="#B96723"
    />
    <path
      d="M11.7928 8.59766L6.53516 20.7662H10.2906L13.3251 9.35288L11.7928 8.59766Z"
      fill="#9B501E"
    />
    <path
      d="M18.5497 5.16315C18.3364 4.63856 17.967 4.22033 17.5092 3.98486L15.5567 2.98059C15.3931 2.89645 15.222 2.85613 15.0534 2.85547L14.5809 3.24057L12.9336 7.66695L13.3253 9.35455C13.6052 9.34296 13.8726 9.16846 14.0207 8.86841L14.8752 7.13801C15.1073 6.66793 15.628 6.51045 16.0292 6.78901L16.7391 7.28197C17.1058 7.53663 17.5795 7.42919 17.8361 7.03329L18.7419 5.63565L18.5497 5.16315Z"
      fill="#EDF4FF"
    />
    <path
      d="M13.9286 3.65651L11.793 8.59927L12.9228 9.25303C13.0523 9.328 13.1895 9.36041 13.3252 9.35456L15.0534 2.85547C14.5917 2.85397 14.1476 3.14943 13.9286 3.65651V3.65651Z"
      fill="#DBEAFF"
    />
    <rect
      x={0.8125}
      y={20.2656}
      width={24.375}
      height={0.881485}
      rx={0.440742}
      fill="#DAC1A8"
    />
  </svg>
);
export default SVGMontainStatus;
