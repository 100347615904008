import { Col } from 'antd';
import { CheckListIcon, Navigation03Icon, PlayIcon } from 'hugeicons-react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { PageRoutes } from '../../../constants';
import { ReactComponent as Home } from '../../../images/home.svg';
import { ReactComponent as LuminousSvg } from '../../../images/luminous.svg';
import { ReactComponent as NewLogoSvg } from '../../../images/new-logo.svg';
import Button from '../../Button';
import UserProfile from '../../UserProfile';
import { footerNavigationBarVisibleBreakpoint } from '../constants';
import { HeaderContentWrapper } from '../styled';
import { HeaderContent, HeaderContentNav, Logo, MainHeaderLinks } from './styled';

export function DefaultHeader({
  loggedInUser,
  changeOnMobile,
  isMobile,
  headerNavButtonsWithHeaderSticky,
  isScrolling,
  showHeaderActions,
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const [width, setWidth] = useState(0);

  const changeWidth = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    changeWidth();
    window.addEventListener('resize', changeWidth);
    return () => {
      window.removeEventListener('resize', changeWidth);
    };
  }, [changeWidth]);
  return (
    <HeaderContentWrapper justifyContent="space-between" hide={isScrolling && headerNavButtonsWithHeaderSticky}>
      {width && (
        <>
          {changeOnMobile && width <= footerNavigationBarVisibleBreakpoint ? (
            <HeaderContent changeOnMobile>
              <Link to={PageRoutes.PROFILE}>
                <UserProfile changeOnMobile size="small" pictureUrl={loggedInUser.picture?.formats.small.url}></UserProfile>
              </Link>
              <Link to={loggedInUser.isAuthenticated ? PageRoutes.HOME : PageRoutes.LANDING}>
                <Logo changeOnMobile>
                  <div style={{ display: 'flex' }}>
                    <NewLogoSvg style={{ marginRight: '10px', width: '40px' }} />
                    <LuminousSvg style={{ marginTop: '8px' }} />
                  </div>
                </Logo>
              </Link>
            </HeaderContent>
          ) : (
            <>
              <Col md={10} lg={10} noPadding>
                <HeaderContent>
                  <Link to={loggedInUser.isAuthenticated ? PageRoutes.HOME : PageRoutes.LANDING}>
                    <Logo changeOnMobile>
                      <div style={{ display: 'flex' }}>
                        <NewLogoSvg style={{ marginRight: '10px', width: '57px' }} />
                        <LuminousSvg style={{ marginTop: '15px' }} />
                      </div>
                    </Logo>
                  </Link>
                  {loggedInUser.isAuthenticated && (
                    <>
                      <Link to={PageRoutes.HOME}>
                        <MainHeaderLinks className={location.pathname === PageRoutes.HOME ? 'active' : ''}>
                          <Home className="home" />
                          {t('menu_dashboard')}
                        </MainHeaderLinks>
                      </Link>
                      <Link to={PageRoutes.SESSIONS_LIBRARY}>
                        <MainHeaderLinks className={location.pathname === PageRoutes.SESSIONS_LIBRARY ? 'active' : ''}>
                          <PlayIcon className="start" />
                          {t('profile_sessions')}
                        </MainHeaderLinks>
                      </Link>
                      <Link to={PageRoutes.PROGRAMS_LIBRARY}>
                        <MainHeaderLinks className={location.pathname === PageRoutes.PROGRAMS_LIBRARY ? 'active' : ''}>
                          <Navigation03Icon className="start" />
                          {t('programs')}
                        </MainHeaderLinks>
                      </Link>
                      <Link to={ PageRoutes.TO_DOS_LIBRARY}>
                        <MainHeaderLinks className={location.pathname === PageRoutes.TO_DOS_LIBRARY ? 'active' : ''}>
                          <CheckListIcon className="start" />
                          {t('profile_to_dos')}
                        </MainHeaderLinks>
                      </Link>
                    </>
                  )}
                </HeaderContent>
              </Col>
              <Col md={2} lg={2} noPadding>
                <HeaderContentNav hiddenLogo={isScrolling && headerNavButtonsWithHeaderSticky}>
                  {isMobile &&
                    isScrolling &&
                    headerNavButtonsWithHeaderSticky &&
                    headerNavButtonsWithHeaderSticky.map((headerNavButton) => headerNavButton)}
                  {!isMobile &&
                    headerNavButtonsWithHeaderSticky &&
                    headerNavButtonsWithHeaderSticky.map((headerNavButton) => headerNavButton)}
                  {loggedInUser.isAuthenticated ? (
                    <Link to={PageRoutes.PROFILE}>
                      <UserProfile size="small" pictureUrl={loggedInUser.picture?.url}></UserProfile>
                    </Link>
                  ) : (
                    showHeaderActions && (
                      <Button styles="secondary" size="large" href={PageRoutes.LOGIN}>
                        {t('login_button')}
                      </Button>
                    )
                  )}
                </HeaderContentNav>
              </Col>
            </>
          )}
        </>
      )}
    </HeaderContentWrapper>
  );
}
