import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../services/apiClient';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchNotStartedPrograms = createAsyncThunk('programs/fetchNotStartedPrograms', async params => {
  const response = await apiClient.get('/programs/not-started', { params });
  return response.data;
});

const notStartedPrograms = createSlice({
  name: 'notStartedPrograms',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchNotStartedPrograms.pending]: state => {
      state.status = 'loading';
    },
    [fetchNotStartedPrograms.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchNotStartedPrograms.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default notStartedPrograms.reducer;
