import { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { getAuthenticatedUser } from '../../utils';

export const withAuth = Component => {
  const currentURL = window.location.pathname + window.location.search;
  localStorage.setItem('currentURL', currentURL);
  class WithAuth extends PureComponent {
    render() {
      const user = getAuthenticatedUser();

      if (user && user.isAuthenticated) {
        localStorage.removeItem('currentURL');
        return <Component {...this.props} user={user} />;
      }

      return <Redirect to="/login" />;
    }
  }

  return WithAuth;
};
