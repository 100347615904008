import styled from 'styled-components';

export default styled.div`
  background: linear-gradient(
    188.75deg,
    #ffd47b -22.61%,
    ${props => props.theme.colors.persimmon} 109.41%,
    ${props => props.theme.colors.persimmon} 109.41%
  );
  border-radius: ${props => props.theme.utils.rem(12)};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.11);
  padding: 0 ${props => props.theme.utils.rem(6)};
  font-size: ${props => props.theme.utils.rem(12)};
  line-height: ${props => props.theme.utils.rem(18)};
  font-weight: 500;
  color: ${props => props.theme.colors.white};
  text-align: center;
  white-space: nowrap;

  ${props => props.inline && `
    display: inline-block;
  `}

  ${props => props.size === 'medium' && `
    padding: 2px 10px;
    line-height: 14px;
    font-weight: 600;

    strong {
      font-weight: 600;
    }
  `}
`;
