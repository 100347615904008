import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../../services/apiClient';

const initialState = {
  insights: {
    data: [],
    status: 'idle',
  },
};

export const fetchInsights = createAsyncThunk('analyticInsights/fetchInsights', async ({companies, tags, fromDate, toDate, hideNames }) => {
  const body = {
    includeDeleted: true,
    includeTeamTag: true,
    companies,
    tags,
    fromDate,
    toDate,
    hideNames,
  }
  const response = await apiClient.post('/analytic/insights', body);
  return response.data;
});

export const downloadFile = createAsyncThunk('analyticInsight/download', async ({companies, tags, fromDate, toDate, hideNames }) => {
  const body = {
    includeDeleted: true,
    includeTeamTag: true,
    companies,
    tags,
    fromDate,
    toDate,
    hideNames,
  }

  const response = await apiClient.post('/analytic/insights/download', body);
  return response.data;
});

const analyticInsights = createSlice({
  name: 'analyticInsights',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchInsights.pending]: state => {
      state.insights.status = 'loading';
    },
    [fetchInsights.fulfilled]: (state, action) => {
      state.insights.status = 'succeeded';
      state.insights.data = action.payload;
    },
    [fetchInsights.rejected]: (state, action) => {
      state.insights.status = 'failed';
      state.error = action.payload;
    },
    [downloadFile.pending]: state => {
      state.fileStatus = 'loading';
    },
    [downloadFile.fulfilled]: (state, action) => {
      state.fileStatus = 'succeeded';
    },
    [downloadFile.rejected]: (state, action) => {
      state.fileStatus = 'failed';
      state.error = action.payload;
    },
  },
});

export default analyticInsights.reducer;
