import { Select } from "antd";
import { useField } from "formik";
import { CancelCircleIcon, CheckmarkSquare01Icon, MinusSignCircleIcon, PaintBrush01Icon, SlidersVerticalIcon } from "hugeicons-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Typography } from "../../stories/Typography";
import { GoalOptionContainer, GoalStatusContainer, GoalStatusSelect, GoalStatusTypoContainer } from "./goalStatus.style";




const { Option } = Select;

//Formik props field are passed to allow the goal status usage in forms
const GoalStatus = ({initialStatus, goalId, ...props}) => {
  const [showStatusChoice, setShowStatusChoice] = useState(false);
  console.log('initStatus ',initialStatus)
  const [status, setStatus] = useState(initialStatus);
  const componentRef = useRef(null);


  //used to pass and update Formik form values
  // eslint-disable-next-line no-unused-vars
  const [field,meta,helpers] = useField(props);
  const { setValue } = helpers;

  const currentStatus = useMemo(() => {
    console.log('sattus ', status)
    switch(status){
      case 'active':
        return { svg : <PaintBrush01Icon />, label: 'On it' };
      case 'completed':
        return { svg : <CheckmarkSquare01Icon /> , label: 'Done'};
      case 'stuck':
        return { svg : <MinusSignCircleIcon /> , label: 'Stuck'};
      case 'not-started':
        return { svg : <CancelCircleIcon /> , label: 'Not started'};
      default:
        return { svg : <SlidersVerticalIcon />, label: 'define'};
    }
  }, [status]);
  const statusListChoice = useMemo(() => {
    const listToReturn = [{
      label: 'On it',
      icon: <PaintBrush01Icon />
    }, {
      label: 'Done',
      icon: <CheckmarkSquare01Icon />
    }, {
      label: 'Stuck',
      icon: <MinusSignCircleIcon />
    }, {
      label: 'Not started',
      icon: <CancelCircleIcon />
    }].filter((item) => item.label !== currentStatus.label);
    return listToReturn;

  }, [currentStatus.label]);

  const handleChangeStatus = useCallback((statusValue) => {
    setValue(statusValue);
    setStatus(statusValue);
  }, [setValue]);
  const handleStatusCardClick = useCallback(() => {
    setShowStatusChoice(!showStatusChoice);
  }, [showStatusChoice]);
  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setShowStatusChoice(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return <>
    <GoalStatusContainer ref={componentRef} label={currentStatus.label} onClick={handleStatusCardClick} >
    {currentStatus.svg}
      <GoalStatusTypoContainer>
      <Typography color={currentStatus.label ? "black" : "#8A8390"} fz="16px" fw="500" lh="110%" ls="-2%">
        {currentStatus.label ?? 'DEFINE'}
      </Typography>
        <Typography color="#8A8390" fz="12px" fw="500" lh="90%" ls="-2%">
        STATUS
        </Typography>
      </GoalStatusTypoContainer>
      <GoalStatusSelect
      open={showStatusChoice}
      size="large"
      className="status"
      dropdownClassName="dropdown-status"
      name="status"
      value={currentStatus.label}
      onBlur={field.onBlur}
      onChange={(statusValue) => handleChangeStatus(statusValue.toLowerCase())}
      >
      { statusListChoice.map((elt, index) => {
        return <Option key={index} value={elt.label === 'On it' ? 'active' : elt.label === 'Done' ? 'completed' : elt.label === 'Not started' ? 'not-started' : elt.label} className='fade-in'  >
          <GoalOptionContainer label={elt.label}>
            {elt.icon}
              <Typography>
              {elt.label}
              </Typography>
          </GoalOptionContainer>
        </Option>
      })

      }

      </GoalStatusSelect>
  </GoalStatusContainer>

  </>;
}

export default GoalStatus;
