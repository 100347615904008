import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../services/apiClient';

const initialState = {
  data: null,
  status: 'idle',
  error: null,
};

export const fetchMessage = createAsyncThunk('welcomeMessage/fetchMessage', async params => {
  const response = await apiClient.get('/programs/custom', { params });
  return response.data;
});

const welcomeMessageSlice = createSlice({
  name: 'welcomeMessage',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMessage.pending]: state => {
      state.status = 'loading';
    },
    [fetchMessage.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchMessage.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default welcomeMessageSlice.reducer;
