import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../services/apiClient';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchUserProgramChallenges = createAsyncThunk(`/actions/fetchUserProgramChallenges`, async (userProgramId) => {
  const response = await apiClient.get(`user-program-answers/${userProgramId}/challenges`);
  return response.data;
});

const actionSlice = createSlice({
  name: 'actionSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUserProgramChallenges.pending]: state => {
      state.status = 'loading';
    },
    [fetchUserProgramChallenges.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchUserProgramChallenges.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default actionSlice.reducer;
