import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../services/apiClient';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchAllSessions = createAsyncThunk('session/fetchAllSessions', async () => {
  const response = await apiClient.get('/user-session-answers', {
    params: {
      'filters[completed]': true,
      populate: [
        'session', 'session.needs', 'session.needs.content',
        'session.content', 'session.content.picture',
        'session.questions', 'session.questions.content',
        'session.questions.content.groups',
        'session.questions.content.options',
        'answers', 'answers.answer'
      ]
    }
  });
  return response.data;
});

const sessionsAllSlice = createSlice({
  name: 'sessionsAllSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllSessions.pending]: state => {
      state.status = 'loading';
    },
    [fetchAllSessions.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchAllSessions.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default sessionsAllSlice.reducer;
