import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import rootReducer from '../reducers';
import { apiSlice } from '../api/apiSlice';

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: process.env.REACT_APP_NODE_ENV !== 'production',
});

setupListeners(store.dispatch);
