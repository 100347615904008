import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../services/apiClient';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchProgramJournaling = createAsyncThunk('userProgram/fetchProgramJournaling', async userProgramId => {
  const { data } = await apiClient.get(`/programs/${userProgramId}/journaling`);

  return data.data;
});

export const deleteProgramJournaling = createAsyncThunk('userProgram/deleteProgramJournaling', async id => {
  await apiClient.delete(`/journaling/${id}`);

  return id;
});

const programJournalingSlice = createSlice({
  name: 'programJournalingSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchProgramJournaling.pending]: state => {
      state.status = 'loading';
    },
    [fetchProgramJournaling.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchProgramJournaling.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [deleteProgramJournaling.pending]: state => {
      state.status = 'loading';
    },
    [deleteProgramJournaling.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = state.data.filter(item => item.id !== action.payload);
    },
    [deleteProgramJournaling.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default programJournalingSlice.reducer;
