import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../services/apiClient';

const populateQuery = [
  'needs.content', 'needs.skills', 'needs.skills.content', 'needs.skills.need',
  'steps.content', 'steps.activities', 'steps.activities.step',
  'steps.activities.article', 'steps.activities.session',
  'steps.activities.article.content', 'steps.activities.article.content.picture',
  'steps.activities.article.content.tips', 'steps.activities.article.content.more',
  'steps.activities.session.content.picture', 'steps.activities.session.content',
  'coaches.content',
  'coaches.picture',
  'coaches.video',
  'content',
  'content.picture',
]

const initialState = {
  data: {
    data: [],
    byNeed: [],
  },
  status: 'idle',
  error: null,
};

export const fetchPrograms = createAsyncThunk('programs/fetchPrograms', async params => {
  const response = await apiClient.get('/programs', {
    params: {
      ...params,
      populate: populateQuery
    }
  });
  return response.data;
});

const programsSlice = createSlice({
  name: 'programsSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPrograms.pending]: state => {
      state.status = 'loading';
    },
    [fetchPrograms.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchPrograms.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default programsSlice.reducer;
