import { getLocale } from '../utils';
import { ArticleContent, CoachContent, NeedContent, ProgramContent, SessionContent, SkillContent } from '../types';
import Auth from './auth';

// This gets the content base on the user's locale for program, session, and article
export default function fetchContent(content) {
  if (content && Array.isArray(content)) {
    const localeContent = content.find((c) => c.locale === Auth.getLanguage());
    if (localeContent) {
      return localeContent;
    }
    // Index 0 should always be available since it is required
    return content[0];
  }
  return content;
}

type Content = ArticleContent | CoachContent | NeedContent | ProgramContent | SessionContent | SkillContent;

export const getContent = (
  contents: Content[],
): ArticleContent | CoachContent | NeedContent | ProgramContent | SessionContent | SkillContent => {
  if (Array.isArray(contents)) {
    const localeContent = contents.find((content) => content.locale === getLocale());
    if (localeContent) {
      return localeContent;
    }
    // Index 0 should always be available since it is required
    return contents[0];
  }
  return contents;
};
