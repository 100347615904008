import { DatePicker } from "antd";
import styled, { css } from "styled-components";


export const StyledDatePicker = styled(DatePicker)`
  ${css`
    margin: 16px 0;
    width: 100%;
    `}

  `;
