import styled, { FlattenSimpleInterpolation } from 'styled-components';
type Props = {
  customStyle?: FlattenSimpleInterpolation;
};
type SquareProps = {
  squareSize?: string;
} & Props;

export const Square = styled.div<SquareProps>`
  width: 100%;
  height: ${(props) => props.squareSize || '300px'};
  border-radius: 4px;
  margin: 10px;
  ${(props) => props.customStyle}
`;

type TextLineProps = {
  textSize?: string;
  textCenter?: boolean;
} & Props;

export const TextLine = styled.div<TextLineProps>`
  width: ${(props) => props.textSize || '100%'};
  height: 25px;
  border-radius: 10px;
  margin: 10px ${(props) => props.textCenter && `auto`};
  ${(props) => props.customStyle}
`;

export const Circle = styled.div<Props>`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin: 10px;
  ${(props) => props.customStyle}
`;

export const ListItem = styled.div<Props>`
  width: 100%;
  height: 60px;
  border-radius: 5px;
  margin: 15px;
  ${(props) => props.customStyle}
`;

export const PlaceholderContent = styled.div<Props>`
  max-width: 1100px;
  margin: 0 auto;
  overflow: hidden;

  ${Square},
  ${TextLine},
  ${Circle},
  ${ListItem} {
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 10%, #f6f7f8 20%, #f6f7f8 100%);
    background-size: 200% 100%;
    animation: bgPos 1.5s linear infinite;
  }

  @keyframes bgPos {
    0% {
      background-position: 50% 0;
    }
    100% {
      background-position: -150% 0;
    }
  }
  ${(props) => props.customStyle}
`;
