import { useCallback } from "react";
import useLockTouchBodyScroll from "../../hooks/useLockTouchBodyScroll";
import { StyledGoalDrawer } from "./goalDrawer.style";




const GoalDrawer = ({
  children,
  isOpen,
  onClose
}) => {
  const className = 'goal-drawer';
  useLockTouchBodyScroll(isOpen,className);

  const handleClose = useCallback((e) => {
    onClose(e);
  }, [onClose]);



  return <>{isOpen && <StyledGoalDrawer
    placement="right"
    visible = {isOpen}
    mask={true}
    maskClosable={true}
    onClose={(e) => handleClose(e)}
    className={className}
    onMouseOver={(e) => {
      e.stopPropagation();
      e.preventDefault();
    }}

  >
    {children}
  </StyledGoalDrawer>}</>;
}



export default GoalDrawer;
