import { useHistory } from 'react-router-dom';
import { PageRoutes } from '../../../constants';
import { ReactComponent as Close } from '../../../images/close-modal.svg';
import { Col } from '../../Col';
import { CloseContainer, CloseWrapper, HeaderContentWrapper } from '../styled';
import { SessionNeed, SessionTitle, SessionTopic } from './styled';

type Props = {
  data: any;
  closeButtonStroke: boolean;
  notifications: boolean;
};

export function SessionHeader({ data, closeButtonStroke, notifications }: Partial<Props>) {
  const history = useHistory();

  const handleCloseClick = () => {
    history.length > 1 ? history.push(PageRoutes.HOME) : history.push('/');
  };

  return (
    <HeaderContentWrapper sessions={data.session_id !== null}>
      <Col xs={5} md={notifications ? 10 : data && data.progress ? 9 : 10} noPadding>
        <div>
          {data.session_title && (
            <>
              <SessionTitle>{data.session_title}</SessionTitle>
              <SessionNeed>{data.need_title}</SessionNeed>
              <SessionTopic>{data.topic_title ? ` - ` + data.topic_title : ''}</SessionTopic>
            </>
          )}
        </div>
      </Col>
      <CloseContainer xs={1} md={2} noPadding>
        <CloseWrapper onClick={handleCloseClick} closeButtonStroke={closeButtonStroke}>
          <Close className="close-icon" />
        </CloseWrapper>
      </CloseContainer>
    </HeaderContentWrapper>
  );
}
