import styled, { css } from "styled-components";
import { GoalSettingContainer, GoalSettingsSelect, GoalSettingsTypoContainer } from "./goalDIfficulty.style";

export const GoalStatusContainer = styled(GoalSettingContainer)`
  .status{
  height: 0;
      min-width: 128px;
  visibility: hidden;
  }
  svg, div {
  color: ${(props) =>
  props.label === 'On it' ? '#2394BC' :
  props.label === 'Done' ? '#08B556' :
  props.label === 'Stuck' ? '#BD273A' :
  props.label === 'Not started' && '#DD9E4A'
  }
  }

  position : relative;
  z-index: 1;
  &::before{
  content: '';
  position: absolute;
  inset:0;
  z-index: 0;
  }
  ${(props) =>
  props.label === 'On it' ? css`
     border: 1px solid #2394BC;
     &::before{

     background: #2394BC2e;
     }

     ` :
  props.label === 'Done' ? css`
     border: 1px solid #08B556;
     &::before{

     background: #08B5562e;
     }

     ` :
  props.label === 'Stuck' ? css`

     border: 1px solid #BD273A;
     &::before{

     background: #BD273A2e;
     }

     ` :
 props.label === 'Not started' && css`
     border: 1px solid #DD9E4A;
     &::before{

     background: #DD9E4A2e;
     }
   `
  }

  `;

export const GoalStatusTypoContainer = styled(GoalSettingsTypoContainer)``;

export const GoalOptionContainer = styled.div`
margin: 8px 0;
display: grid;
grid-template-columns: 0fr 1fr;
place-items: center start;
gap: 8px;
svg, div {
color: ${(props) =>
props.label === 'On it' ? '#2394BC' :
props.label === 'Done' ? '#08B556' :
props.label === 'Stuck' ? '#BD273A' :
props.label === 'Not started' && '#DD9E4A'
}
}
`;

export const GoalStatusSelect = styled(GoalSettingsSelect)`


  `;
