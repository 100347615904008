import Auth from './auth';
import en from '../locales/en.json';
import fr from '../locales/fr.json';
import pt from '../locales/pt.json';

const langs = {
  en,
  fr,
  pt,
};

export default {
  load: () => {
    let lang = null;

    lang = Auth.getLanguage();

    if (langs[lang]) {
      return langs[lang];
    }
    return {};
  },
};
