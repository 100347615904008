import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Popover } from 'react-text-selection-popover';
import { useTextSelection } from 'use-text-selection';

import {
  Add02Icon,
  ClipboardIcon,
  FloppyDiskIcon,
  MirrorIcon,
  PlusSignCircleIcon,
  Target02Icon,
  WorkoutSportIcon
} from 'hugeicons-react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import {
  useCreateGoalMutation,
  useCreateInsightMutation,
  useCreateUserActionMutation
} from '../../api/apiSlice';
import { ExtendedUserProfile } from '../../types';
import { Grid } from '../Grid';
import { Header } from '../Header';
import { Placeholder } from '../Placeholder';
import { PlaceholderContent } from '../Placeholder/styled';
import { throttle } from 'lodash';

// ========================= STYLE =================================

const Z_INDEX_POPUP = 9999;
const PopUpValidationTextContainer = styled.div`
  display: grid;
  place-items: center;
  margin: 0 8px;
  grid-template-columns: repeat(2, 0fr);
  text-wrap: nowrap;
  svg {
    margin: 4px;
  }
`;
const PopUpSelectionTextContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: left;
  margin: 0 8px;
  cursor: default;
  grid-auto-flow: column;
  gap: 4px;
`;
const PopUpIconText = styled.span`
  width: 0;
  height: 0;
  opacity: 0;

  position: absolute;
`;

const PopUpIconContainer = styled.div<{ containerWidth: number }>`
  position: relative;
  z-index: ${Z_INDEX_POPUP + 1};

  cursor: pointer;

  background: transparent;
  padding: 4px;
  border-radius: 8px;

  transition: border-radius 0.8s ease-in-out;

  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 0fr;
  grid-gap: 4px;
  align-items: center;

  svg {
    color: #ff7864;
    --filter-color: #ff7864;
    --blur-amount: 4px;
    --increased-blur-amount: 8px;
    animation: pulsate-blur 2s ease infinite;
  }

  &:hover {
    z-index: ${Z_INDEX_POPUP + 2};

    border-radius: 12px;
    ${PopUpIconText} {
      animation: fadein 1.2s, fillHorizontal 1.5s, fillVertical 0.2s;
      position: relative;
      opacity: 1;
      width: max-content;
      height: unset;
      text-wrap: nowrap;
    }
    &::before {
      content: '';
      position: absolute;
      // filter: drop-shadow(#ff7864 1px 1px 4px);
      inset: 0;
      background: rgba(29, 63, 73, 0.2);
      border-radius: 12px;
      transition: inset 1.5s ease-in-out;
    }
    &:active {
      &::before {
        inset: 16px;
      }
    }

    svg {
      animation-play-state: paused;

      filter: drop-shadow(#ff7864 1px 1px 8px);
    }
  }
`;

const PopUpSelectionContainer = styled.div<{
  xPosition: number;
  yPosition: number;
  selectedWidth: number;
  isSaving?: boolean;
}>`
  position: absolute;
  // width: clamp(180px ,${(props) =>
    props.selectedWidth ? props.selectedWidth + 'px' : '50%'},240px);
  top: ${(props) => (props.yPosition ? props.yPosition + 'px' : '50%')};
  left: ${(props) => (props.xPosition ? props.xPosition + 'px' : '50%')};
  z-index: ${Z_INDEX_POPUP};
  display: grid;
  grid-template-columns: repeat(4, 0fr);

  background: rgb(29, 63, 73);
  color: white;
  border-radius: 8px;
  // box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.5), inset 0 -3px 4px -1px rgba(0, 0, 0, 0.05),
  //   0 -4px 15px -1px rgba(255, 255, 255, 0.6), inset 0 3px 4px -1px rgba(255, 255, 255, 0.2),
  //   inset 0 0 5px 1px rgba(255, 255, 255, 0.8), inset 0 20px 30px 0 rgba(255, 255, 255, 0.2);
  ${(props) =>
    props.isSaving &&
    css`
      grid-template-columns: 0fr 0fr;

      min-width: 248px;
      place-content: center;
      place-items: center;
      gap: 0px;
      & > div {
        overflow: visible;
      }

      ${PlaceholderContent} {
        height: 32px;
        width: 28px;
        display: grid;
        place-items: center;
      }
    `}
  .clipboard-popup {
    display: grid;
    grid-template-columns: 0fr 1fr;
    gap: 8px;
    text-wrap: nowrap;
    padding: 8px;
    place-items: center;
    font-weight: 500;
    font-size: 16px;
  }
`;
// ========================= COMPONENT =================================
interface Props {
  user: ExtendedUserProfile;
  title: string;
  changeOnMobile: boolean;
  fluid: boolean;
  hideHeader: boolean;
  showHeaderActions: boolean;
  withHeaderSticky: boolean;
}

export function Layout({
  children,
  user,
  title,
  changeOnMobile,
  fluid,
  hideHeader,
  showHeaderActions,
  withHeaderSticky
}: PropsWithChildren<Partial<Props>>) {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [canTriggerPopover, setTriggerPopover] = useState(true);
  const [canTriggerValidationPopover, setValidationPopover] = useState(false);
  const [showClipboardCopied, setShowClipboardCopied] = useState(false);
  const [isSavingElement, setSavingElement] = useState(false);
  const textSelection = useTextSelection(wrapperRef.current as HTMLElement | undefined);
  const [previousSelection, setPreviousSelection] = useState<string | null>(null);
  const [createGoal] = useCreateGoalMutation();
  const [createInsight] = useCreateInsightMutation();
  const [createUserAction] = useCreateUserActionMutation();
  const displayValidationPopover = useCallback(() => {
    setValidationPopover(true);
    setSavingElement(false);

    const timeoutId = setTimeout(() => {
      setValidationPopover(false);
      setTriggerPopover(true);
    }, 2500);
    return () => clearTimeout(timeoutId);
  }, []);
  const copyToClipboard = useCallback(
    throttle((text: string) => {
      console.log('copying');
      let input = document.createElement('textarea');
      input.innerHTML = text;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
    }, 2000),
    []
  );
  const handleGoalClick = useCallback(
    async (e, clientRect, textSelection: string) => {
      e.stopPropagation();
      setTriggerPopover(false);
      setSavingElement(true);
      if (textSelection) {
        await createGoal({
          title: textSelection,
          description: textSelection,
          userId: user ? user.id.toString() : ''
        }).then(() => {
          displayValidationPopover();
        });
      }

      e.preventDefault();
    },
    [createGoal, displayValidationPopover, user]
  );
  const handleActionClick = useCallback(
    async (e, clientRect, textSelection: string) => {
      e.stopPropagation();
      setTriggerPopover(false);
      setSavingElement(true);

      await createUserAction({
        title: textSelection,
        note: textSelection,
        userId: user ? user.id.toString() : '',
        contextType: 'user',
        contextId: 0,
        motivationLevel: 4,
        status: 'not-started'
      }).then(() => {
        displayValidationPopover();
      });

      // setTriggerPopover(false);

      e.preventDefault();
    },
    [createUserAction, displayValidationPopover, user]
  );
  const handleInsightClick = useCallback(
    async (e, clientRect, textSelection: string) => {
      e.stopPropagation();
      setTriggerPopover(false);
      setSavingElement(true);

      await createInsight({
        title: textSelection,
        content: textSelection,
        userId: user ? user.id.toString() : '',
        color: ''
      }).then(() => {
        displayValidationPopover();
      });
      // setTriggerPopover(false);

      e.preventDefault();
    },
    [createInsight, displayValidationPopover, user]
  );

  const renderHeader = () => {
    if (hideHeader) {
      return <></>;
    }

    return (
      <Header
        loggedInUser={user}
        showHeaderActions={showHeaderActions}
        changeOnMobile={changeOnMobile}
        {...(withHeaderSticky && { sticky: true })}></Header>
    );
  };
  const renderLoadingPopover = useCallback(() => {
    return (
      <div>
        <Popover
          target={document.body}
          mount={document.body}
          render={() => {
            const yOffset = window.scrollY;
            const xOffset = window.scrollX;
            // eslint-disable-next-line no-constant-condition
            return isSavingElement ? (
              <PopUpSelectionContainer
                isSaving
                onMouseDown={(e) => e.preventDefault()}
                className="one-line-fade-in"
                selectedWidth={textSelection?.clientRect?.width ?? 0}
                xPosition={(textSelection?.clientRect?.x ?? 0) + xOffset}
                yPosition={
                  textSelection?.clientRect?.y ? textSelection?.clientRect?.y - 40 + yOffset : 0
                }>
                <Placeholder
                  type="circles"
                  columns={1}
                  textSize="8px"
                  customStyles={css`
                    width: 24px !important;
                    height: 24px !important;
                    filter: drop-shadow(#ff7864 1px 1px 4px);
                    display: grid;
                    place-items: center;
                    margin: auto !important;
                    background-image: linear-gradient(
                      to top right,
                      #ff7d64 0%,
                      #fc9581 10%,
                      rgb(251 131 91) 20%,
                      #ff6b4e 100%
                    ) !important;
                    background-size: 200% 200% !important;
                    animation: newLoadingBgPos 3s linear infinite !important; // Use the new animation
                    &:before {
                      content: '';
                      outline: 3px solid #ff7d64;

                      background: rgba(29, 63, 73, 0.8);
                      border-radius: 32px;
                      width: 16px;
                      height: 16px;
                      display: flex;
                      animation: fadein 3s infinite alternate;
                      filter: drop-shadow(#ff7864 1px 1px 1px);
                    }
                  `}
                />
                <Placeholder
                  type="circles"
                  columns={1}
                  textSize="8px"
                  customStyles={css`
                    width: 24px !important;
                    height: 24px !important;
                    filter: drop-shadow(#ff7864 1px 1px 4px);
                    display: grid;
                    place-items: center;
                    margin: auto !important;
                    background-image: linear-gradient(
                      to top right,
                      #ff7d64 0%,
                      #fc9581 10%,
                      rgb(251 131 91) 20%,
                      #ff6b4e 100%
                    ) !important;
                    background-size: 200% 200% !important;
                    animation: newLoadingBgPos 3s linear infinite !important; // Use the new animation
                    &:before {
                      content: '';
                      outline: 3px solid #ff7d64;
                      background: rgba(29, 63, 73, 0.8);
                      border-radius: 32px;
                      width: 16px;
                      height: 16px;
                      display: flex;
                      animation: reversefadein 3s infinite alternate;
                      filter: drop-shadow(#ff7864 1px 1px 1px);
                    }
                  `}
                />
              </PopUpSelectionContainer>
            ) : (
              <></>
            );
          }}
        />
      </div>
    );
  }, [
    isSavingElement,
    textSelection?.clientRect?.width,
    textSelection?.clientRect?.x,
    textSelection?.clientRect?.y
  ]);
  const renderValidationPopover = useCallback(() => {
    return (
      <div>
        <Popover
          target={document.body}
          mount={document.body}
          render={() => {
            const yOffset = window.scrollY;
            const xOffset = window.scrollX;
            return canTriggerValidationPopover ? (
              <PopUpSelectionContainer
                onMouseDown={(e) => e.preventDefault()}
                className="one-line-fade-in"
                selectedWidth={textSelection?.clientRect?.width ?? 0}
                xPosition={(textSelection?.clientRect?.x ?? 0) + xOffset}
                yPosition={
                  textSelection?.clientRect?.y ? textSelection?.clientRect?.y - 40 + yOffset : 0
                }>
                <PopUpValidationTextContainer>
                  <FloppyDiskIcon /> {t('highlight_insight_saved')}
                </PopUpValidationTextContainer>
              </PopUpSelectionContainer>
            ) : (
              <></>
            );
          }}
        />
      </div>
    );
  }, [
    canTriggerValidationPopover,
    t,
    textSelection?.clientRect?.width,
    textSelection?.clientRect?.x,
    textSelection?.clientRect?.y
  ]);

  const renderPopover = useCallback(() => {
    return (
      <div>
        <Popover
          target={document.body}
          render={() => {
            const yOffset = window.scrollY;
            const xOffset = window.scrollX;
            return !canTriggerValidationPopover &&
              canTriggerPopover &&
              textSelection != null &&
              textSelection?.textContent ? (
              <PopUpSelectionContainer
                onMouseDown={(e) => e.preventDefault()}
                className="one-line-fade-in"
                selectedWidth={textSelection?.clientRect?.width ?? 0}
                xPosition={(textSelection?.clientRect?.x ?? 0) + xOffset}
                yPosition={
                  textSelection?.clientRect?.y ? textSelection?.clientRect?.y - 40 + yOffset : 0
                }>
                <PopUpSelectionTextContainer>
                  <PlusSignCircleIcon />
                  {t('save_selection')}
                </PopUpSelectionTextContainer>
                <PopUpIconContainer
                  onMouseDown={(e) =>
                    handleGoalClick(
                      e,
                      textSelection!.clientRect,
                      textSelection?.textContent ?? ('' as string)
                    )
                  }
                  containerWidth={(textSelection?.clientRect?.width ?? 0) as number}>
                  <PopUpIconText>{t('goal_save')}</PopUpIconText>
                  <Target02Icon />
                </PopUpIconContainer>
                <PopUpIconContainer
                  onMouseDown={(e) =>
                    handleActionClick(
                      e,
                      textSelection!.clientRect,
                      textSelection?.textContent ?? ('' as string)
                    )
                  }
                  containerWidth={(textSelection?.clientRect?.width ?? 0) as number}>
                  <PopUpIconText>{t('action_save')}</PopUpIconText>
                  <WorkoutSportIcon />
                </PopUpIconContainer>
                {/*<PopUpIconContainer
                  onMouseDown={(e) =>
                    handleInsightClick(
                      e,
                      textSelection!.clientRect,
                      textSelection?.textContent ?? ('' as string)
                    )
                  }
                  containerWidth={(textSelection?.clientRect?.width ?? 0) as number}>
                  <PopUpIconText>{t('insight_save')}</PopUpIconText>
                  <MirrorIcon />
                </PopUpIconContainer>*/}
              </PopUpSelectionContainer>
            ) : (
              <></>
            );
          }}
          mount={document.body}
        />
      </div>
    );
  }, [
    canTriggerPopover,
    canTriggerValidationPopover,
    handleActionClick,
    handleGoalClick,
    t,
    textSelection
  ]);
  const renderClipboardNotification = useCallback(() => {
    if (!showClipboardCopied) return null;

    return (
      <div>
        <Popover
          target={document.body}
          mount={document.body}
          render={() => {
            const yOffset = window.scrollY;
            const xOffset = window.scrollX;
            return (
              <PopUpSelectionContainer
                onMouseDown={(e) => e.preventDefault()}
                className="one-line-fade-in"
                selectedWidth={textSelection?.clientRect?.width ?? 0}
                xPosition={xOffset}
                yPosition={yOffset}>
                <div className="clipboard-popup">
                  <ClipboardIcon />
                  <span>Copied !</span>
                </div>
              </PopUpSelectionContainer>
            );
          }}
        />
      </div>
    );
  }, [showClipboardCopied, textSelection?.clientRect]);
  useEffect(() => {
    document.title = title ? `Luminous - ${title}` : 'Luminous';
  }, [title]);
  useEffect(() => {
    if (
      textSelection != null &&
      textSelection?.textContent &&
      textSelection?.textContent.trim() !== '' &&
      textSelection.textContent !== previousSelection
    ) {
      copyToClipboard(textSelection?.textContent);
      setShowClipboardCopied(true);
      setPreviousSelection(textSelection.textContent);
      setTimeout(() => {
        setShowClipboardCopied(false);
      }, 4000);
    }
  }, [textSelection, copyToClipboard, previousSelection]);
  return (
    <>
      {fluid ? (
        <>
          {renderHeader()}
          {children}
          {renderPopover()}
          {renderValidationPopover()}
          {renderLoadingPopover()}
          {renderClipboardNotification()}
        </>
      ) : (
        <Grid fluid>
          <>
            {renderHeader()}
            {children}
            {renderPopover()}
            {renderValidationPopover()}
            {renderLoadingPopover()}
            {renderClipboardNotification()}
          </>
        </Grid>
      )}
    </>
  );
}
