import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../../services/apiClient';

const initialState = {
  data: null,
  error: false,
  errorMessage: ``,
  pending: true,
};

export const confirm = createAsyncThunk(`confirmation`, async ({ token, isProgram }) => {
  const response = await apiClient.post(`/mentor/${isProgram ? 'program' : 'goal'}/accept?token=${token}`);
  return response.data;
});

const confirmationSlice = createSlice({
  name: `confirmationSlice`,
  initialState,
  reducers: {},
  extraReducers: {
    [confirm.pending]: state => {
      state.pending = true;
    },
    [confirm.fulfilled]: (state, action) => {
      state.pending = false;
      state.data = action.payload;
    },
    [confirm.rejected]: state => {
      state.pending = false;
      state.error = true;
    },
  },
});

export default confirmationSlice.reducer;
