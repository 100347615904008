import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../services/apiClient';

const initialState = {
  data: {},
  status: 'idle',
  error: null,
};

export const getUserProgramSkills = createAsyncThunk('userProgram/getUserProgramSkills', async programId => {
  const response = await apiClient.get(`/program/skills/${programId}`);

  return response.data;
});

const userProgramNewSkillsSlice = createSlice({
  name: 'userProgramNewSkillsSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [getUserProgramSkills.pending]: state => {
      state.status = 'loading';
    },
    [getUserProgramSkills.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [getUserProgramSkills.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default userProgramNewSkillsSlice.reducer;
