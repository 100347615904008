const SVGCakeStatus = (props) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.8711 20.1562L4.875 22.6172C3.99711 22.7635 3.64453 22.2768 3.64453 21.3867L2.82422 20.9766V14.8242L3.64453 13.1836L22.1016 10.3125L22.5117 11.543V17.6953L22.1016 18.9258C22.1016 19.6299 21.5656 20.0405 20.8711 20.1562Z"
      fill="#FFEFB7"
    />
    <path
      d="M21.3082 20.5444L4.5013 23.3454C4.29497 23.3798 4.08363 23.3688 3.88197 23.3133C3.68031 23.2577 3.49317 23.1589 3.33356 23.0237C3.17395 22.8885 3.04571 22.7202 2.95775 22.5304C2.86979 22.3406 2.82422 22.134 2.82422 21.9248V20.8906L22.5117 17.6094V19.1236C22.5117 19.4646 22.3908 19.7945 22.1704 20.0546C21.95 20.3148 21.6445 20.4883 21.3082 20.5444Z"
      fill="#E9B665"
    />
    <path
      d="M22.5117 11.5936L2.82422 14.8748V12.6873L4.05469 11.8848L20.8711 9.08203L22.5117 9.40606V11.5936Z"
      fill="#794A1E"
    />
    <path
      d="M22.5117 9.40536L2.82422 12.6866L9.81661 2.4669C9.85305 2.41364 9.90114 2.3694 9.95725 2.33753C10.0134 2.30567 10.076 2.28701 10.1404 2.28299C12.6739 2.12466 15.1981 2.71561 17.398 3.98217C19.598 5.24872 21.3765 7.13484 22.5117 9.40536V9.40536Z"
      fill="#925826"
    />
    <path
      d="M18.1679 5.02787C18.1681 5.57966 18.0171 6.12096 17.7314 6.59303C17.4457 7.0651 17.0362 7.4499 16.5473 7.70568C15.5988 8.20094 14.4381 7.66774 13.4865 7.03097L12.6662 6.21066C11.7946 5.62772 11.0153 4.10092 11.0235 4.01068C11.0778 3.41192 11.3095 2.84296 11.6888 2.37654C12.0682 1.91013 12.5781 1.56746 13.1532 1.39235L15.3481 0.723794C15.6329 0.637123 15.9334 0.614794 16.2279 0.658419C16.5224 0.702045 16.8035 0.810535 17.0509 0.976053C17.2984 1.14157 17.506 1.35998 17.6587 1.61551C17.8114 1.87104 17.9055 2.15731 17.9341 2.45363L18.1541 4.73717C18.1633 4.83424 18.1679 4.93114 18.1679 5.02787V5.02787Z"
      fill="#E53E3E"
    />
    <path
      d="M16.5478 7.70663C16.0666 7.95858 15.5264 8.07613 14.9841 8.04689C14.4417 8.01766 13.9173 7.84273 13.466 7.54052L12.354 6.79711C11.9404 6.52084 11.6014 6.14668 11.3673 5.70787C11.1331 5.26906 11.011 4.7792 11.0117 4.28183C11.0117 4.19211 11.0158 4.10187 11.024 4.01164C11.0732 3.98595 11.127 3.97047 11.1823 3.96611C11.2376 3.96176 11.2932 3.96862 11.3458 3.98629C11.3984 4.00396 11.4468 4.03207 11.4883 4.06894C11.5297 4.1058 11.5633 4.15066 11.587 4.20082L12.1566 5.40771C12.2098 5.52065 12.2993 5.61254 12.4108 5.66874C12.5223 5.72495 12.6493 5.74225 12.7718 5.71789L13.646 5.5446C13.699 5.53401 13.7536 5.53398 13.8066 5.54452C13.8596 5.55505 13.9101 5.57594 13.955 5.60599C14 5.63604 14.0386 5.67466 14.0686 5.71963C14.0986 5.76461 14.1194 5.81506 14.1299 5.86811L14.3037 6.74277C14.328 6.86516 14.3925 6.97593 14.487 7.05742C14.5815 7.1389 14.7005 7.18642 14.8252 7.1924L16.1582 7.25802C16.2136 7.26077 16.2679 7.27469 16.3178 7.29896C16.3677 7.32323 16.4122 7.35734 16.4486 7.39925C16.485 7.44115 16.5126 7.48998 16.5296 7.54281C16.5467 7.59563 16.5529 7.65136 16.5478 7.70663V7.70663Z"
      fill="#48BB78"
    />
    <path
      d="M12.4627 3.6199C12.4255 3.61986 12.3891 3.60974 12.3573 3.59063C12.3255 3.57152 12.2994 3.54412 12.282 3.51137C12.2645 3.47862 12.2562 3.44174 12.2581 3.40467C12.2599 3.36759 12.2718 3.33171 12.2924 3.30085L12.5204 2.95991C12.5353 2.93724 12.5545 2.91773 12.5769 2.90252C12.5993 2.88732 12.6246 2.87671 12.6511 2.8713C12.6777 2.8659 12.705 2.86582 12.7316 2.87105C12.7582 2.87629 12.7835 2.88674 12.806 2.90181C12.8286 2.91688 12.8479 2.93626 12.8629 2.95884C12.8778 2.98142 12.8882 3.00674 12.8934 3.03335C12.8985 3.05996 12.8983 3.08732 12.8928 3.11386C12.8873 3.14039 12.8766 3.16558 12.8613 3.18795L12.6332 3.52884C12.6144 3.55687 12.5891 3.57984 12.5593 3.59572C12.5296 3.61161 12.4964 3.61991 12.4627 3.6199V3.6199Z"
      fill="#F3E7C7"
    />
    <path
      d="M14.5096 4.98806C14.4724 4.98802 14.436 4.97791 14.4042 4.95879C14.3724 4.93968 14.3463 4.91228 14.3288 4.87952C14.3114 4.84676 14.3031 4.80987 14.3049 4.77279C14.3068 4.7357 14.3187 4.69982 14.3393 4.66896L14.5674 4.32806C14.5822 4.30523 14.6013 4.28556 14.6238 4.2702C14.6462 4.25485 14.6715 4.24411 14.6981 4.23861C14.7248 4.23311 14.7522 4.23297 14.7789 4.23818C14.8056 4.2434 14.831 4.25387 14.8536 4.26899C14.8762 4.28411 14.8956 4.30357 14.9106 4.32625C14.9256 4.34892 14.936 4.37436 14.9411 4.40107C14.9461 4.42779 14.9459 4.45525 14.9402 4.48186C14.9346 4.50846 14.9238 4.53369 14.9083 4.55606L14.6803 4.897C14.6615 4.92506 14.6361 4.94805 14.6064 4.96393C14.5766 4.97981 14.5433 4.9881 14.5096 4.98806V4.98806Z"
      fill="#F3E7C7"
    />
    <path
      d="M16.5525 6.35524C16.5154 6.35521 16.479 6.3451 16.4472 6.32598C16.4153 6.30687 16.3893 6.27947 16.3718 6.24671C16.3543 6.21395 16.3461 6.17706 16.3479 6.13998C16.3498 6.10289 16.3616 6.067 16.3823 6.03614L16.6103 5.69525C16.625 5.67242 16.6442 5.65275 16.6667 5.63739C16.6891 5.62203 16.7144 5.61129 16.741 5.6058C16.7676 5.6003 16.7951 5.60015 16.8218 5.60537C16.8485 5.61058 16.8739 5.62106 16.8965 5.63618C16.9191 5.65129 16.9385 5.67076 16.9535 5.69344C16.9685 5.71611 16.9788 5.74155 16.9839 5.76826C16.989 5.79498 16.9887 5.82244 16.9831 5.84904C16.9775 5.87565 16.9666 5.90088 16.9512 5.92325L16.7232 6.26414C16.7044 6.29221 16.6791 6.31521 16.6493 6.33111C16.6195 6.347 16.5863 6.35529 16.5525 6.35524V6.35524Z"
      fill="#F3E7C7"
    />
    <path
      d="M14.1697 3.28103C14.1326 3.28099 14.0962 3.27088 14.0643 3.25176C14.0325 3.23265 14.0065 3.20525 13.989 3.17249C13.9715 3.13973 13.9633 3.10284 13.9651 3.06576C13.9669 3.02867 13.9788 2.99279 13.9995 2.96192L14.2275 2.62103C14.2423 2.5982 14.2614 2.57853 14.2839 2.56317C14.3063 2.54782 14.3316 2.53708 14.3582 2.53158C14.3849 2.52608 14.4123 2.52593 14.439 2.53115C14.4657 2.53637 14.4911 2.54684 14.5137 2.56196C14.5363 2.57708 14.5557 2.59654 14.5707 2.61922C14.5857 2.64189 14.5961 2.66733 14.6012 2.69404C14.6062 2.72076 14.606 2.74822 14.6003 2.77483C14.5947 2.80143 14.5839 2.82666 14.5684 2.84903L14.3404 3.18997C14.3216 3.21801 14.2962 3.24099 14.2665 3.25687C14.2367 3.27276 14.2035 3.28105 14.1697 3.28103V3.28103Z"
      fill="#F3E7C7"
    />
    <path
      d="M16.2166 4.64821C16.1795 4.64818 16.1431 4.63806 16.1112 4.61895C16.0794 4.59983 16.0533 4.57244 16.0359 4.53968C16.0184 4.50692 16.0101 4.47003 16.012 4.43295C16.0138 4.39586 16.0257 4.35997 16.0463 4.32911L16.2744 3.98822C16.2892 3.96539 16.3083 3.94572 16.3308 3.93036C16.3532 3.915 16.3785 3.90426 16.4051 3.89877C16.4318 3.89327 16.4592 3.89312 16.4859 3.89834C16.5126 3.90355 16.538 3.91402 16.5606 3.92914C16.5832 3.94426 16.6026 3.96373 16.6176 3.9864C16.6326 4.00908 16.6429 4.03452 16.648 4.06123C16.6531 4.08795 16.6528 4.11541 16.6472 4.14201C16.6416 4.16862 16.6307 4.19384 16.6153 4.21622L16.3873 4.55711C16.3685 4.58516 16.3431 4.60816 16.3134 4.62405C16.2836 4.63994 16.2504 4.64824 16.2166 4.64821V4.64821Z"
      fill="#F3E7C7"
    />
    <path
      d="M15.8768 2.94128C15.8397 2.94126 15.8032 2.93115 15.7714 2.91203C15.7395 2.89291 15.7135 2.8655 15.696 2.83272C15.6785 2.79995 15.6703 2.76304 15.6721 2.72594C15.674 2.68884 15.6859 2.65294 15.7066 2.62208L15.9346 2.28119C15.9494 2.25835 15.9685 2.23869 15.991 2.22333C16.0134 2.20797 16.0387 2.19723 16.0653 2.19173C16.092 2.18624 16.1194 2.18609 16.1461 2.19131C16.1728 2.19652 16.1982 2.20699 16.2208 2.22211C16.2434 2.23723 16.2628 2.2567 16.2778 2.27937C16.2928 2.30205 16.3032 2.32748 16.3083 2.3542C16.3133 2.38091 16.3131 2.40837 16.3074 2.43498C16.3018 2.46159 16.291 2.48681 16.2755 2.50918L16.0475 2.85008C16.0287 2.87816 16.0034 2.90119 15.9736 2.9171C15.9438 2.93302 15.9106 2.94132 15.8768 2.94128V2.94128Z"
      fill="#F3E7C7"
    />
  </svg>
);
export default SVGCakeStatus;
